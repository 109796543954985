
export class WPUtils {

    /**
     * Walk up the DOM to find a parent having a given class name.
     * @param elem
     * @param clsName
     * @returns {boolean}
     */
    parentsHaveClass(elem, clsName) {
        if(elem == null) {
            return false; // exit condition if the element is null (reached the root node)
        }
        if(elem.classList && elem.classList.contains(clsName)) {
            return true; // if we have a match, we return true and terminate the recursion
        }
        return this.parentsHaveClass(elem.parentElement, clsName);
    }
    
    /**
     * Check if the component is being edited in an RTE in AEM
     */
    inAemEdit() {
        if(this.parentsHaveClass(this, 'aem-AuthorLayer-Edit')) {
            if(this.parentsHaveClass(this, 'is-initializing')) {
                return true;

            } else if(this.parentsHaveClass(this, 'is-edited') ||
                (this.parentsHaveClass(this, 'rte-editor') && this.parentsHaveClass(this, ' rte-fullscreen'))) {
                this.innerHTML = '';
                return true;
            }
        }

        return false;
    }
}
import {
    GATEWAY_EVENTS
} from './events';

export class SelectedTagsModule {

    /**
     * @constructor - 
     * @param -
     */
    constructor(menu, options) {
        let defaults = {
            labelTarget: "#wp-selected-tags-clear-label",
            container: ".wp-selected-tags ul"
        }
        this.options = this.deepExtend({}, defaults, options);
        this.element = this.options.container;
        this.menu = menu;

        this.clearBTLabel = document.querySelector(this.options.labelTarget).innerHTML;
        this.tags = [];

        this.startListeners();
    }

    startListeners() {
        var ref = this;
        document.body.addEventListener(GATEWAY_EVENTS.MENU_UPDATED, function (event) {
           let tags = event.detail.tags;
            ref.createBaseStructure();
            if (typeof tags === 'string') {
                ref.addItem(tags);
            } else if (Array.isArray(tags)) {
                tags.forEach(tag => {
                    ref.addItem(tag);
                });
            }
            ref.resize();


        });

       
        document.body.addEventListener(GATEWAY_EVENTS.MENU_VISIBILITY_CHANGED, this.resize.bind(this));

        window.addEventListener("resize", this.resize.bind(this));

        var selectedTag = document.querySelector(this.options.container);

        let taglist = this.options.container + " li";
        document.querySelector(this.options.container).addEventListener("click", function (event) {
                var target = event.target.closest("li");

                if (target.classList.contains("clear-tags")) {
                    ref.menu.clearTags();
                } else {
                    ref.unselectTag(target.getAttribute("checkbox-id"));
                }
            });
      
        
     
    }



    resize() {
        document.querySelector(".wp-gateway-menu").style.position = "relative"; 
        document.querySelector(".wp-gateway-menu").style.top = "auto";          
        

        let selectedTagsHeight = parseFloat(getComputedStyle(document.querySelector(".wp-selected-tags"), null).height.replace("px", ""));

        if (selectedTagsHeight > 60 && window.innerWidth > 640) {

            let gatewayVisibilityOffetTop = document.querySelector(".wp-gateway-menu-visibility").getBoundingClientRect.top + document.body.scrollTop;
            let gatewayMenuHeight = parseFloat(getComputedStyle(document.querySelector(".wp-gateway-menu-visibility"), null).height.replace("px", ""));
            let gatewayMenuOffsetTop =  document.querySelector(".wp-gateway-menu").getBoundingClientRect.top + document.body.scrollTop;

            document.querySelector(".wp-gateway-menu").style.position = "relative"; 
            document.querySelector(".wp-gateway-menu").style.top = gatewayVisibilityOffetTop + gatewayMenuHeight - gatewayMenuOffsetTop +24 + "px" ;          
        }
    }

    unselectTag(tag) {
        document.querySelector("input[id='" + tag + "']").click();
    }

    getTagLabel(tag) {
        return document.querySelector("label[for='" + tag + "']").innerHTML;
    }
    createBaseStructure() {

         var selectedTag = document.querySelector(this.options.container);

        let liTags = selectedTag.querySelectorAll("li");
        for(var i = 1; i < liTags.length; i++) {
            liTags[i].remove();
        }

        this.list = this.element;
        this.tags = [];
    }

    addItem(tag) {

        var label = this.getTagLabel(tag);
        let list = document.querySelector(this.list);
        if (this.tags.length == 0) {
            
            list.insertAdjacentHTML('beforeend',"<li class='clear-tags'><a>" + this.clearBTLabel + "</a></li>");
        }

        list.insertAdjacentHTML('beforeend',"<li checkbox-id=" + tag + ">" + label + "<svg viewbox='0 0 17.48 13.6'><use xlink:href='/content/dam/autodesk/icons.svg#icon-svg-close'></use></svg></li>");
        this.tags.push(tag);
    }

    clickClearItem() {

    }

    //Javascript version of $.extend found on http://youmightnotneedjquery.com/
    deepExtend(out)  {
        out = out || {};
      
        for (var i = 1; i < arguments.length; i++) {
          var obj = arguments[i];
      
          if (!obj)
            continue;
      
          for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (typeof obj[key] === 'object'){
                if(obj[key] instanceof Array == true)
                  out[key] = obj[key].slice(0);
                else
                  out[key] = deepExtend(out[key], obj[key]);
              }
              else
                out[key] = obj[key];
            }
          }
        }
      
        return out;
      };

}
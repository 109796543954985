
function faqAccordion() {
    
         //because classList isn't supported in IE11...
         var containsClass = function (obj, className) {
            return obj.getAttribute("class").indexOf(className) > -1;
          };
          var addToClass = function (obj, className) {
            obj.setAttribute("class", obj.getAttribute("class") + " " + className);
          };
          var removeFromClass = function (obj, className) {
            var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
            obj.setAttribute("class", obj.getAttribute("class").replace(reg, ""));
          };
          var toggleFromClass = function (obj, className) {
            if (obj.getAttribute("class").indexOf(className) == -1) {
              obj.setAttribute("class", obj.getAttribute("class") + " " + className);
            } else {
              var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
              obj.setAttribute("class", obj.getAttribute("class").replace(reg, ""));
            }
          };

          document.querySelectorAll('.wp-faq-accordion table').forEach(function (e) {
        
            e.onclick = function (el) {
                el.preventDefault();
                let visibleIcons = e.querySelectorAll('.accordion-heading-icon-visible');
                let hiddenIcons = e.querySelectorAll('.accordion-heading-icon-hidden');
                let faqAnswers = e.parentNode.querySelectorAll('.wp-faq-answer');
    
                visibleIcons.forEach(function (v_icon) {
                    if (containsClass(v_icon,'hide')) {
                        removeFromClass(v_icon, 'hide');
                        hiddenIcons.forEach(function (h_icon) {addToClass(h_icon,'hide'); })
                        faqAnswers.forEach(function (answer) {removeFromClass(answer,'hide'); })
    
                        //animate 
                        let topMenuHeight = document.querySelector('.mfe-universal-header').offsetHeight;
                        let position = v_icon.parentNode.getBoundingClientRect().top + window.scrollY - topMenuHeight;
                        let topMenu = document.querySelector('.mfe-universal-header').offsetHeight;
                        window.scroll({top:position, behavior: 'smooth'});
                    }
                    else {
                       addToClass( v_icon,'hide');
                        hiddenIcons.forEach(function (h_icon) {removeFromClass(h_icon,'hide'); })
                        faqAnswers.forEach(function (answer) {addToClass(answer, 'hide'); })
                        window.dispatchEvent(new Event('resize'));
                    }
                });
            };
        });





    // document.querySelectorAll('.wp-faq-accordion table').forEach(function (e) {
        
    //     e.onclick = function (el) {
    //         el.preventDefault();
    //         let visibleIcons = e.querySelectorAll('.accordion-heading-icon-visible');
    //         let hiddenIcons = e.querySelectorAll('.accordion-heading-icon-hidden');
    //         let faqAnswers = e.parentNode.querySelectorAll('.wp-faq-answer');

    //         visibleIcons.forEach(function (v_icon) {
    //             if (v_icon.containsClass('hide')) {
    //                 v_icon.removeFromClass('hide');
    //                 hiddenIcons.forEach(function (h_icon) { h_icon.addToClass('hide'); })
    //                 faqAnswers.forEach(function (answer) { answer.removeFromClas('hide'); })

    //                 //animate 
    //                 let position = v_icon.parentNode.getBoundingClientRect().top + window.scrollY -54;
    //                 window.scroll({top:position, behavior: 'smooth'});
    //             }
    //             else {
    //                 v_icon.classList.add('hide');
    //                 hiddenIcons.forEach(function (h_icon) { h_icon.removeFromClas('hide'); })
    //                 faqAnswers.forEach(function (answer) { answer.addToClass('hide'); })
    //                 window.dispatchEvent(new Event('resize'));
    //             }
    //         });
    //     };
    // });


    
}

export {faqAccordion};


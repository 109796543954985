import {
    SearchAPI
} from "../../search-api/search-api.js";
import {
    GATEWAY_EVENTS
} from './events';
import {
    GatewaySorting
} from './sorting';
import {
    URLManage
} from './urlManagement';
// import mustache from 'mustache';
import compiledMenuTemplate from "../hbs-templates/menu-item.hbs";

export class GatewayMenu {
    /**
     * @constructor - Creates a new gateway code
     * @param -
     */
    constructor(options) {
        var defaults = {
            menu: "wp-gateway-menu",
            menuMustache: "#gateway-menu-mustache",
            menuVisibilityContainer: ".gateway-app",
            menuVisibilityClickableArea: ".wp-gateway-menu-visibility.wp-filter-top-wrap",
            menuVisibilityAnalyticsTracking: ".wp-filter-top-wrap",
            menuVisibilityOff: ".wp-gw-bt-off",
            menuVisibilityOn: ".wp-gw-bt-on",
            menuVisibilityOffMobile: ".wp-gateway-filter-header-mobile svg",
            menuVisibilityOnMobile: ".wp-gateway-menu-visibility",
            defaultMenuVisibility: "show",
            // from Base.js
            // Turn console log to false
            enableDevelopmentMode: false,
            tagPrefix: "personalization-tags:all-products/",
            showMoreLimit: 6,
            showMoreLabel: "#wp-menu-show-more-label",
            showLessLabel: "#wp-menu-show-less-label"
        }
        var menuMustache = "<ul><li></li></ul>";
        this.URLManager = new URLManage();
        if (options.menuData) {
            if (options.menuData.menuItems.length == 0) {
                options.menuData = menu;
            }
        }
        this.options = Object.assign(defaults, options);
        this.init();
    }
    /**
     * @method init - Start the Menu and whole app
     */
    init() {
        this.searchAPI = new SearchAPI();
        this.urlParams = this.URLManager.getURLParams(this.options.tagPrefix);
        this.lastDataLoaded = {};
        this.currentTags = [];
        this.parseMenu(this.options.menuData);

        //to keep the original data
        this.originalMenuData = this.options.menuData;
        this.sorting = new GatewaySorting();
        this.setSorting(this.urlParams.sorting === this.sorting.SORT_BY.A_Z ? this.sorting.SORT_BY.A_Z : this.sorting.SORT_BY.POPULAR);
        // Visibility controls
        this.menuVisibilityClickableArea = document.querySelector(this.options.menuVisibilityClickableArea);
        this.menuVisibilityWrapper = document.querySelector(this.options.menuVisibilityAnalyticsTracking);


        this.MobileBtHideFilter = document.querySelector(this.options.menuVisibilityOffMobile);
        this.MobileBtShowFilter = document.querySelector(this.options.menuVisibilityOnMobile);
        this.menuVisibilityContainer = document.querySelector(this.options.menuVisibilityContainer);
        // Menu visibility from URL
        var menuVisibilityOnLoad = this.urlParams.menu ? this.urlParams.menu : this.options.defaultMenuVisibility;
        // On mobile we don't let the menu open on load
        if (this.mobileUI()) {
            menuVisibilityOnLoad = false;
        }
     
        this.changeMenuVisibility(menuVisibilityOnLoad, true);
        this.startListeners();
        var filtersToLoad = [];
        if (this.urlParams.filters) {
            if (Array.isArray(this.urlParams.filters)) {
                filtersToLoad = this.urlParams.filters;
            } else {
                filtersToLoad.push(this.urlParams.filters);
            }
        }
        this.load(filtersToLoad);
    }
    /**
     * @method mobileUI - 
     */
    mobileUI(sorting) {
        return window.innerWidth < 640;
    }
    /**
     * @method 
     */
    setSorting(sorting) {
        this.currentSorting = sorting;
        var sortingRadio = document.querySelector('[data-wat-dynamic-filter-group="sorting"] [value="' + this.currentSorting + '"]');

        //removed the checked class to featured and a-z li
        document.querySelectorAll('[data-wat-dynamic-filter-group="sorting"] li').forEach(function (e) {
            e.classList.remove("checked");
        });
        
       
        //set the data-wat-dynamic-filter-selected to false 
        document.querySelector('[data-wat-dynamic-filter-group="sorting"] [data-wat-dynamic-filter-selected="true"]').setAttribute("data-wat-dynamic-filter-selected", "false");
        
        //add the checked class to  the element selected
        sortingRadio.parentNode.classList.add("checked");


        sortingRadio.checked = true;
        sortingRadio.setAttribute("data-wat-dynamic-filter-selected", "true");
    }
    /**
     * @method 
     */
    onMenuVisibilityButtonClick(event) {
        // Desktop
        if (this.menuVisibility === "show") {
            this.changeMenuVisibility(false);
        } else if (this.menuVisibility === "hide") {
            this.changeMenuVisibility(true);
        }
    }
    /**
     * @method changeMenuVisibility - 
     */
    changeMenuVisibility(newState, skipURL) {
        // Reset
        this.menuVisibilityContainer.classList.remove("wp-gw-menu-off", "wp-gw-menu-on");

        // Update data-wat-value for filter button
        // Doing it before we update the state because the labeling is better
        this.menuVisibilityWrapper.setAttribute("data-wat-val", (!this.menuVisibility ? "show" : this.menuVisibility) + " filters");

        if (newState === true || newState === false) {
            this.menuVisibility = newState ? "show" : "hide";
        } else if (newState === "show" || newState === "hide") {
            this.menuVisibility = newState;
        } else {
            this.menuVisibility = this.options.defaultMenuVisibility;
        }
        if (!skipURL) {
            this.updateURL();
        }
        this.menuVisibilityContainer.classList.add(this.menuVisibility === "show" ? "wp-gw-menu-on" : "wp-gw-menu-off");
       
        document.body.dispatchEvent(new CustomEvent(GATEWAY_EVENTS.MENU_VISIBILITY_CHANGED));
    }
    /**
     * @method parseMenu - Parse and Renders the menu from the menu information we have
     */
    parseMenu(menuData) {
        if (!menuData) {
            menuData = menu;
        }
        this.menuData = menuData;
        // creates/resets bucket for Tags pre-calculation
        this.resetSearchPredictedResults();
        // Creates the menu from the calculated data
        document.querySelector(".wp-gateway-menu").innerHTML = compiledMenuTemplate(menuData);
    }
    /**
     * @method resetSearchPredictedResults - creates/resets bucket for Tags pre-calculation
     */
    resetSearchPredictedResults() {
        // creates/resets bucket for Tags pre-calculation
        this.tagPredictedResults = {};
        this.tagWorkflowCategories = {};
        var firstLoad = false;
        if (!this.menuCollapseState) {
            firstLoad = true;
            this.menuCollapseState = {};
        }
        // example of a filter in the object above {"personalization-tags:all-products/pdm-products/manufacturing-production" : 4}
        var ref = this;
        if (this.menuData.menuItems) {
            this.menuData.menuItems.forEach(menuItem => {
                // Default is opened
                if (!ref.menuCollapseState[menuItem.menuID] && firstLoad) {
                    ref.menuCollapseState[menuItem.menuID] = true
                }
                menuItem.opened = ref.menuCollapseState[menuItem.menuID];
                menuItem.sublinks.forEach(sublinks => {
                    ref.tagPredictedResults[sublinks.tag] = 0;
                    ref.tagWorkflowCategories[sublinks.tag] = 0;
                });
            });
        }
    }
    /**
     * @method parseMenu - Parse and Renders the menu from the menu information we have
     */

    updateMenuForSearchPredictions() {
        //    Parse predictions and update menu
        var newMenu = {
            ...this.menuData
        };
        var ref = this;
        var checkbox, checbkoxParentMenu;
        // For each menu items (links)
        if (newMenu.menuItems) {
            newMenu.menuItems.forEach(menuItem => {
                menuItem.sublinks.forEach(sublink => {
                    checkbox = document.querySelector("[id='" + sublink.tag + "']");

                    //VR filter (no tags), so checkbox becomes null
                    if(checkbox != null) {
                    checbkoxParentMenu = checkbox.parentNode;
                    
                    let countSpan = checbkoxParentMenu.querySelector("span");
                    checkbox.classList.remove("disabled", "checked");
                    checbkoxParentMenu.classList.remove("menu-disabled");
                    // Check if we have found tags while parsing the cards
                    if (ref.tagPredictedResults[sublink.tag] != 0) {
                        sublink.count = ref.tagPredictedResults[sublink.tag];
                        sublink.disabled = false;
                    } else {
                        // Tag not found in results
                        sublink.count = 0;
                        sublink.disabled = true;
                        checkbox.classList.add("disabled");
                        checbkoxParentMenu.classList.add("menu-disabled");
                    }
                   
                    if (countSpan == null) {
                        checbkoxParentMenu.querySelector("label").insertAdjacentHTML('beforeend', "<span>" + (sublink.count == 0 ? "" : ("(" + sublink.count + ")")) + "</span>")
                    } else {
                        countSpan.innerHTML = (sublink.count == 0 ? "" : ("(" + sublink.count + ")"));
                    }
                    checbkoxParentMenu.setAttribute("results-count", sublink.count);
                    // setting the status of the link back to what is is (look at tags)
                    if (ref.currentTags.concat(",").indexOf(sublink.tag) != -1) {
                        sublink.checked = true;
                        checkbox.classList.add("checked");
                    } else {
                        sublink.checked = false;
                    }
                    checkbox.setAttribute("data-wat-dynamic-filter-selected", sublink.checked);
                    checkbox.checked = sublink.checked;
                }
                });
            });
        }
        // Disabled, add class disabled
        // Disabled parent wp-menu-checkbox add class menu-disabled
        // Checked, add class checked
        // count update label span and 
        // parent results-count="count"
    }
    clearTags() {
        this.bypass = true;
        let menu = document.querySelector('.wp-gateway-menu');
        menu.querySelectorAll('input[type=checkbox]').forEach(function(e) {
            e.checked= false;
            e.setAttribute("data-wat-dynamic-filter-selected", "false");
        });
      
        this.menuUpdated();
        this.bypass = false;
    }
    /**
     * @method startListeners - Start listening to the events that triggers actions for the menu
     */
    startListeners() {
        var ref = this;
        document.querySelectorAll(".wp-gateway-menu input[type=checkbox]", "[data-wat-dynamic-filter-group='sorting'] input[type=radio]").forEach(function (e) {
            e.addEventListener("click", function () {
                this.setAttribute("data-wat-dynamic-filter-selected", this.checked);
            });
        });
        
        document.querySelectorAll(".wp-gateway-menu input[type=checkbox]").forEach(function(e) {
            e.addEventListener("change", function () {
                if (ref.bypass != true) {
                    this.setAttribute("data-wat-dynamic-filter-selected", this.checked);
                    ref.menuUpdated()
                }
            })
        });

        document.querySelectorAll("[data-wat-dynamic-filter-group='sorting'] input[type=radio]").forEach(function(e) {
            e.addEventListener("change", function () {
                ref.sortingUpdated()
            });
        });
        
        //We need those handlers to keep the "this" in the right scope and to refer to the same function
        this.onMenuVisibilityButtonClickHandler = this.onMenuVisibilityButtonClick.bind(this);
        this.menuAccordionClickHandler = this.menuAccordionClick.bind(this);

        document.querySelectorAll(".menuCollapse").forEach((e) => {
            e.addEventListener("click",  this.menuAccordionClickHandler);
        })
     

        this.menuVisibilityClickableArea.addEventListener("click",  this.onMenuVisibilityButtonClickHandler);                
        this.MobileBtHideFilter.addEventListener("click",  this.onMenuVisibilityButtonClickHandler);
        this.MobileBtShowFilter.addEventListener("click",  this.onMenuVisibilityButtonClickHandler);

             
        document.querySelectorAll('[data-wat-dynamic-filter-group="sorting"] li').forEach(function(e) {
            e.addEventListener("click", function () {
                var radio = this.querySelector("[data-wat-dynamic-filter-group='sorting'] input[type=radio]");
            if (!radio.checked) {
                radio.click();
            }
            });
        });
    }
    /**
     * @method load - Launch the load/search process
     */
    load(tags) {
        // creates/resets bucket for Tags pre-calculation
        this.resetSearchPredictedResults();
        // Do stuff to gets parameters from menu
        // Default load
        var ref = this;
        this.currentTags = tags;
        document.body.dispatchEvent(new CustomEvent(GATEWAY_EVENTS.LOAD_START));
        this.searchAPI.search(tags, function (data) {
            ref.loadHandler(data, tags);
        }, this.searchAPI.settings.folder);
    }
    /**
     * @method menuUpdated - 
     */
    menuUpdated() {
        // Get the filters selected
        this.currentTags = this.getTagsFromMenuSelected();
        this.updateURL(this.currentTags, this.currentSorting);
        // Then we load
        this.load(this.currentTags);
    }
    /**
     * @method sortingUpdated - 
     */
    sortingUpdated() {
        this.setSorting(this.getSortingFromUI());
        this.updateURL(this.currentTags, this.currentSorting);
        // We skip the load (where reset happens) so we neet to trigger it
        this.resetSearchPredictedResults();
        // No need to reload, we reparse the last load
        this.loadHandler(this.lastDataLoaded, this.currentTags);
    }
    /**
     * @method load - Launch the load/search process
     */
    updateURL(tags, sorting) {
        // Do stuff to gets parameters from menu
        // Default load
        var urlParams = {};
        urlParams.filters = tags ? tags : this.currentTags;
        urlParams.sorting = sorting ? sorting : this.currentSorting;
        urlParams.menu = this.menuVisibility;
        urlParams.page = 1; // Page should be put back to 1
        this.URLManager.setURLParams(urlParams, this.options.tagPrefix);
    }
    /**
     * @method getTagsFromMenuSelected - 
     * @throws {Array} - Array of all tags that are currently selected in the menu (ready to be sent to server)
     */
    getTagsFromMenuSelected() {
        var tags = [];
        // Get all checkbox selected
        document.querySelectorAll(".wp-gateway-menu input[type=checkbox]:checked").forEach(function (element) {
            element.setAttribute("data-wat-dynamic-filter-selected", "true");
            tags.push(element.getAttribute("value"));
        })
        
        return tags;
    }
    /**
     * @method getSortingFromUI - 
     * @throws {String} - 
     */
    getSortingFromUI() {
        return document.querySelector('[data-wat-dynamic-filter-group="sorting"] input:checked').getAttribute('value');
    }
    /**
     * @method loadHandler - Handling of the search result load
     * @param {object} data - JSON object representing the current search
     * @param {Array} tags - current selected tags
     * @dispatch {EVENT} - Event that data is loaded along the data
     */
    loadHandler(data, tags, sorting) {
        this.lastDataLoaded = data;
        var sortBy = sorting ? sorting : this.currentSorting;
        data = this.cleanUpResults(data, tags, sortBy);
        data = this.parseResults(data, tags, sortBy);

        document.body.dispatchEvent(new CustomEvent(GATEWAY_EVENTS.LOAD_DONE, {
            detail: {
                data: data
            }
        }));

        this.updateMenuForSearchPredictions();
        this.createShowMore();


           //Dispatch the even and pass it data
           document.body.dispatchEvent(new CustomEvent(GATEWAY_EVENTS.RESULTS_UPDATED, {
            detail: {
                data: data,
                tags: tags
            }
        }), );

        //Dispatch the even and pass it data
        document.body.dispatchEvent(new CustomEvent(GATEWAY_EVENTS.MENU_UPDATED, {
            detail: {
                data: data,
                tags: this.currentTags
            }
        }), );
     
    }
    /**
     * @method parseResults - Remove products that are not available or that are not product-cards
     * @param {object} data - JSON object representing the current search
     */
    cleanUpResults(data) {
        for (let x = data.content.length - 1; x >= 0; x--) {
            const element = data.content[x];
        
            // Ignore content that is not product card
            // Ignore product that are not available
            if ((element["cardData"]["object-type"] != "product-card" && element["cardData"]["object-type"] != "product-sorrento-card") || element["cardData"]["product-availability"] == false) {
                data.content.splice(x, 1);
            }
        }
        data.results = data.content.length;
        return data;
    }
    /**
     * @method parseResults - Parse the results and manipulates the ranking / ordering
     * @param {object} data - JSON object representing the current search
     * @param {Array} tags - current selected tags
     * @throws {Array} data - updated ordered data object
     */
    parseResults(data, tags, sortBy) {
        return this.sorting.sortResults(data, tags, sortBy, this);
    }
    /**
     * @method parseResults - Parse the results and manipulates the ranking / ordering
     * @param {string} tag - tags that we are increasing future search result
     */
    increaseTagPredictedResults(tag) {
        if (typeof (this.tagPredictedResults) !== "undefined") {
            if (typeof (this.tagPredictedResults[tag]) !== "undefined") {
                this.tagPredictedResults[tag]++;
            }
        }
    }
    /**
     * @method menuAccordionClick - 
     * @param {event} event - 
     */
    menuAccordionClick(event) {
        var collapseMenu = (event.target).closest(".wp-gateway-main-collapse");
        var id = collapseMenu.getAttribute("collapse-menu-id");
        // if it is false or doesn't exist we open it
        if (!this.menuCollapseState[id]) {
            collapseMenu.classList.add("opened");
            this.menuCollapseState[id] = true;
        } else {
            collapseMenu.classList.remove("opened");
            this.menuCollapseState[id] = false;
        }
        // Update state of menu accordions 
        // this.menuCollapseState
        // collapse-menu-id
    }
    /**
     * @method createShowMore - 
     * @param {} ttt - 
     */
    createShowMore(target) {
        // Remove all previous show more - show less
        let seemore = document.querySelector(".wp-accordion-container .wp-see-more-bt");
        let seeless = document.querySelector(".wp-accordion-container .wp-see-less-bt");

        if(seemore != null) {
            seemore.remove();
        }

        if(seeless != null) {
            seeless.remove();
        }

        // Find menu that needs a show more
        // Show a max of 7 filters in the workflow menu
        let workflowsFiltersList = document.querySelectorAll(".workflows-filters li:not(.menu-disabled)");

        if (workflowsFiltersList.length > 6) {
            var accordion = document.querySelector(".workflows-filters");
            var accordionContainer = accordion.querySelector(".wp-accordion-container");
            // Set the class 
            accordion.classList.add("show-more");
            // Create the buttons
            var seeMoreBt = document.createElement('div');
            seeMoreBt.className = "wp-see-more-bt";
            seeMoreBt.innerHTML = '<span></span><svg class="wd-icon"><use xlink:href="/content/dam/autodesk/icons.svg#icon-svg-arrow-button"></use></svg>';
                       
            seeMoreBt.querySelector("span").innerHTML = document.querySelector(this.options.showMoreLabel).innerHTML;
            
            var seeLessBt = document.createElement('div');
            seeLessBt.className = "wp-see-less-bt";
            seeLessBt.innerHTML = '<span></span><svg class="wd-icon"><use xlink:href="/content/dam/autodesk/icons.svg#icon-svg-arrow-button"></use></svg>';
           
            seeLessBt.querySelector("span").innerHTML = document.querySelector(this.options.showLessLabel).innerHTML;


            // Append the buttons
            accordionContainer.append(seeMoreBt);
            accordionContainer.append(seeLessBt);

            // Default is hidden, hide filters after the first 6 (this is overwritten in the products-gateways.js file)
            //If this.options.showMoreLimit needs to be updated, update also in products-gateway.js in the  Layout change on Menu Update section
            let list = accordionContainer.querySelectorAll("li:not(.menu-disabled)");
            for(let i = this.options.showMoreLimit; i < list.length; i ++) {
                list[i].style.display = "none";
            }

        }
        // Label comes from
        // showMoreLabel
        // showMoreLimit

        this.clickShowMoreHandler = this.clickShowMore.bind(this);

        //check if the buttons are on the page before adding a listener to them
        seemore = document.querySelector(".wp-accordion-container .wp-see-more-bt");
        seeless = document.querySelector(".wp-accordion-container .wp-see-less-bt");

        if(seemore != null) {
            document.querySelector(".wp-see-more-bt").addEventListener("click",  this.clickShowMoreHandler);
        }

        if(seeless != null) {
            document.querySelector(".wp-see-less-bt").addEventListener("click",  this.clickShowMoreHandler);
        }
       

    }
    /**
     * @method removeShowMore - 
     * @param {} ttt - 
     */
    removeShowMore(target) {
        // see-more-opened
    }
    /**
     * @method clickShowMore - 
     * @param {} ttt - 
     */
    clickShowMore(event) {
        // see-more-opened
        var accordion = (event.target).closest(".wp-gateway-main-collapse");
        if (accordion.classList.contains("see-more-opened")) {
            let list = accordion.querySelectorAll("li")
            for(let i = this.options.showMoreLimit; i < list.length; i ++) {
                list[i].style.display = "none";
            }
            accordion.classList.remove("see-more-opened");
        } else {
            accordion.querySelectorAll("li:not([results-count='0'])").forEach(function(e) {
                e.style.display = "";
            })
 
            accordion.classList.add("see-more-opened");
        }
    }
}
export class SearchAPI {

    static defaultCurrencies = {
        en_US: "USD",
        fr_CA: "CAD",
        en_CA: "CAD",
        es_MX: "MXN",
        en_AU: "AUD",
        zh_CN: "CNY",
        ja_JP: "JPY",
        ko_KR: "KRW",
        zh_TW: "USD",
        en_IN: "INR",
        en_MY: "USD",
        en_NZ: "NZD",
        en_SG: "SGD",
        en_HK: "USD",
        en_ZA: "USD",
        en_AE: "USD",
        pt_BR: "BRL",
        es_AR: "USD",
        cs_CZ: "CZK",
        da_DK: "DKK",
        de_DE: "EUR",
        de_CH: "CHF",
        en_GB: "GBP",
        es_ES: "EUR",
        fr_BE: "EUR",
        fr_FR: "EUR",
        fr_CH: "CHF",
        hu_HU: "EUR",
        it_IT: "EUR",
        it_CH: "CHF",
        nl_BE: "EUR",
        en_IE: "EUR",
        en_NL: "EUR",
        nl_NL: "EUR",
        no_NO: "NOK",
        pl_PL: "PLN",
        pt_PT: "EUR",
        fi_FI: "EUR",
        sv_SE: "SEK",
        ru_RU: "RUB",
        tr_TR: "TRY"
    }

    constructor(options) {
        // Default values for Destination AEC
        let defaults = {
            dataID: "productsGatewayContentData",
            local: ""
        };


        this.defaultCurrencies = {
            en_US: "USD",
            fr_CA: "CAD",
            en_CA: "CAD",
            es_MX: "MXN",
            en_AU: "AUD",
            zh_CN: "CNY",
            ja_JP: "JPY",
            ko_KR: "KRW",
            zh_TW: "USD",
            en_IN: "INR",
            en_MY: "USD",
            en_NZ: "NZD",
            en_SG: "SGD",
            en_HK: "USD",
            en_ZA: "USD",
            en_AE: "USD",
            pt_BR: "BRL",
            es_AR: "USD",
            cs_CZ: "CZK",
            da_DK: "DKK",
            de_DE: "EUR",
            de_CH: "CHF",
            en_GB: "GBP",
            es_ES: "EUR",
            fr_BE: "EUR",
            fr_FR: "EUR",
            fr_CH: "CHF",
            hu_HU: "EUR",
            it_IT: "EUR",
            it_CH: "CHF",
            nl_BE: "EUR",
            en_IE: "EUR",
            en_NL: "EUR",
            nl_NL: "EUR",
            no_NO: "NOK",
            pl_PL: "PLN",
            pt_PT: "EUR",
            fi_FI: "EUR",
            sv_SE: "SEK",
            ru_RU: "RUB",
            tr_TR: "TRY"
        }

        this.settings = Object.assign(defaults, options);
        this.cardResultsMapping = [];
        this.setLocal();
        this.setPricingSource();
    }

    setLocal(){
        if(this.settings.local == ""){
            this.settings.local = document.documentElement.lang.replace("-", "_");
        }
    }

    setPricingSource(){
        this.ppgPricing = false;
        // Priority to PPG if available
        if(window.adsk.api.ppg && document.documentElement.getAttribute("data-subscription-ppg-enabled") == "enabled"){
            console.log("Gateways API - Pricing Source is PPG");
            this.ppgPricing = true;
        }
    }

    setCurrency(){
        // Verify all exists for pricing information
        window.adsk = window.adsk || {};
        window.adsk.api = window.adsk.api || {};
        window.adsk.api.pds = window.adsk.api.pds || {};
        window.adsk.api.pds.data = window.adsk.api.pds.data || {};

        // no currency by default
        this.settings.currency = false;

        // Identify the currencies available for this geo
        var currencies = [];
        
        if(this.ppgPricing){
            // With the new PPG data format, there is no currency at the root level, every price has different the pricing value for each currency
            for (var productKey in window.adsk.api.ppg.data) {
                if (Object.prototype.hasOwnProperty.call(window.adsk.api.ppg.data, productKey)) {
                //We take the first product to look for the currencyFormat object to check what currency is available
                    if(window.adsk.api.ppg.data[productKey].currencyFormats){
                        let product = window.adsk.api.ppg.data[productKey];
                        //We take all currencies and fill our array
                        product.currencyFormats.forEach(currencyInfo => {
                            if(currencyInfo.currency){
                                currencies.push(currencyInfo.currency);
                            }
                        });
                        
                        break;
                    }
                }
            }
        }else{
            //Fill out the currency available for the current data set
            for(var currency in window.adsk.api.pds.data) {
                currencies.push(currency);
            }
            // If the currency doesn't have price or is not found we remove it
            if(!window.adsk.api.pds.data[this.settings.currency]){
                this.settings.currency = false;
            }
        }

        // Identify the currency applicable here
        if(currencies.length > 0){
            if(currencies.length == 1){
                this.settings.currency = currencies[0];
            }else{
                let currencyNotFound = true;
                // Check if the user ever selected another currency from the mfe-buy-component
                if(localStorage.getItem("currencyCode")){
                    // Currency format in the local storage looks like this : "cop|co" "currency|country"
                    let currencyPref = localStorage.getItem("currencyCode").split("|")[0].toUpperCase();
                    //Check if it available for this geo
                    if(currencies.join(",").indexOf(currencyPref) != -1){
                        this.settings.currency = localStorage.getItem("currencyCode").split("|")[0].toUpperCase();
                        currencyNotFound = false;
                    }
                }

                //No currency found in preferences we will find the good default value
                if(currencyNotFound){
                    //With the new Pricing source we will use the same default values as the Buy container
                    if(this.ppgPricing){
                        if(this.defaultCurrencies[this.settings.local]){
                            this.settings.currency = this.defaultCurrencies[this.settings.local];   
                        }else{
                            // All else fails we select the first currency returned...
                            this.settings.currency = currencies[0];
                        }
                    }else{
                        // Not selected, we make sure USD is present and selected it as LATAM presently default to USD
                        if(currencies.join(",").indexOf("USD") != -1){
                            this.settings.currency = "USD";
                        }else{
                            // All else fails we select the first currency returned...
                            this.settings.currency = currencies[0];
                        }

                    }
                }
            }
        }
    }


    // Previous implementation
    // We remove the direct PAth as we are only ready from the current page
    // load(directpath, onSuccess,onFail){
    load(callback, dataID) {
        let _dataID = this.settings.dataID;
        if (dataID) {
            _dataID = dataID;
        }

        // Verify all exists
        window.adsk = window.adsk || {};
        window.adsk.contenthub = window.adsk.contenthub || {};

        // Validate data associated to key actually exists
        if (window.adsk.contenthub[_dataID]) {
            this.data = this.parseCardInfo(window.adsk.contenthub[_dataID]);
        } else {
            console.debug("SearchAPI - Error - Data not found for key " + _dataID);
        }

        // Once the data is converted and aggretated (price + content) we launch the callback function
        if (typeof callback === 'function') {
            callback(this.data);
        }

    }

    //TODO : Text search is removed for now
            // This is emulating an AND query for all the tags passed in the function
    textSearch(terms, tags, callback){

        if(!this.data){
            this.load();
            //return;
        }

        let results = this.filterResultsWithTag(tags);

        // Extra verifications to look for the terms search in the text field
        if(Array.isArray(results.content) && terms != ""){
                let filteredResults = [];
                // Loop through the results
                results.content.forEach(function(result){
                    // Create a string of all tags joined to perform search faster
                    if(result["cardData"]){
                        if(result["cardData"]["product-name"]){
                            let searchable = result["cardData"]["product-name"];
                            // Check if the current result have this tag
                            if(searchable.indexOf(terms) != -1){
                                filteredResults.push(result);
                            }
                        }
                    }
                });

                results.content = filteredResults;
                results.results = filteredResults.length;
        }

        // Once the data is converted and aggretated (price + content) we launch the callback function
        if (typeof callback === 'function') {
            callback(results);
        }
    }

    search(tags, callback) {

        if(!this.data){
            this.load();
            //return;
        }
        
        let results = this.filterResultsWithTag(tags);

        // Once the data is converted and aggretated (price + content) we launch the callback function
        if (typeof callback === 'function') {
            callback(results);
        }
    }

    filterResultsWithTag(tags){
        let results = {};
        let filteredResults = [];

        if(Array.isArray(this.data.content) && Array.isArray(tags)){
            if(tags.length >0){
                // Loop through the results
                var resultsLocations = {};
                var tagsFound = 0;
                this.data.content.forEach(function(result){

                    tagsFound = 0;
                    // Loop all the tags in the query
                    tags.forEach(function(tag){
                        if(Array.isArray(result["aem-tags"])){
                            // Create a string of all tags joined to perform search faster
                            let tagsSearchable = result["aem-tags"].join(",")+",";
                            // Check if the current result have this tag
                            if(tagsSearchable.indexOf(tag+',') != -1){
                                // Tag found
                                tagsFound++;
                            }
                        }
                    });

                    // If all tags were found AND this was not found before
                    if(tagsFound == tags.length && !(resultsLocations[result.location.replace("/", "--")])){
                        filteredResults.push(result);
                        resultsLocations[result.location.replace("/", "--")] = true;
                    }
                });

                results.content = filteredResults;
                results.results = filteredResults.length;
            }else{
                this.data.content.forEach(function(result){
                    filteredResults.push(result);
                });
                results.content = filteredResults;
                results.results = filteredResults.length;
            }
        }
        return results;
    }


    setAnalyticsData(searchTerm, searchFilters, numberOfResults) {

        if(window.digitalData){
            window.digitalData.search = {};
            window.digitalData.search["searchTerm"] = searchTerm;
            window.digitalData.search["searchFilters"] = {};
            for(var i=0; i<searchFilters.length;i++){
                window.digitalData.search["searchFilters"]["filter"+i] = searchFilters[i];
            }
            window.digitalData.search["numberOfResults"] = numberOfResults;
        }else{
            this.searchlog("Error - window.digitalData not defined");
        }


        try{
            analytics.context.resolveWait('search-results');
        }catch(e){
            this.searchlog("Error : analytics.context not defined");
        }
    }


    searchlog(msg) {
        //console.log("Search API log : "+msg);
    }


    /**
     * 
     */
    parseCardInfo(data) {

        // Identify the correct currency
        this.setCurrency();

        if(this.ppgPricing){
            data = this.parseCardInfoWithPPGData(data);
        }else{
            data = this.parseCardInfoWithPDSData(data);
        }
        
        return data
    }

            // Tranform the supplemental-data format to an object-key array
    transformSupplementalData(result){

            if (Array.isArray(result["supplemental-data"]) == 1) {
                result.cardData = {};
                for (let y = 0; y < result["supplemental-data"].length; y++) {
                    if (result["supplemental-data"][y]["value"] != "" || result["supplemental-data"][y]["value"] === false) {
                        result.cardData[result["supplemental-data"][y]["key"]] = result["supplemental-data"][y]["value"];
                    }
                }
            }

            return result;
    }

    /**
     * 
     */
    parseCardInfoWithPPGData(data) {
        // Verify all exists for pricing information
        window.adsk = window.adsk || {};
        window.adsk.api = window.adsk.api || {};
        window.adsk.api.ppg = window.adsk.api.ppg || {};
        window.adsk.api.ppg.data = window.adsk.api.ppg.data || {};

        let result;
        for (let x = 0; x < data.results; x++) {
            result = data.content[x];

            // Tranform the supplemental-data format to an object-key array
            result = this.transformSupplementalData(result);
            

            // Add the Pricing information if applicable
            if(this.settings.currency){
                if(result.cardData["plc"]){
                    // Check if we have a match for the PLC in the content hub info
                    if(window.adsk.api.ppg.data[result.cardData["plc"]]){
                        result["ppg-info"] = {};
                        result["ppg-info"]["prices"] = this.ppgPriceCurrencyFiltering(window.adsk.api.ppg.data[result.cardData["plc"]]["prices"], this.settings.currency);
                    }
                }
            }

            // save index to mapping object
            result = this.saveCardMapping(result, x);
        }

        return data;
    }

                    // Need to cleanup prices to remove prices that are not in the currency

    ppgPriceCurrencyFiltering(prices, currency){
        //ignore filtering when there are only 3 terms.
        if(Array.isArray(prices) && prices.length > 3){
            let newPrices = prices.filter(function(price){
                return price.currency == currency;
            })
            return newPrices;
        }
        return prices;
    }

    /**
     * 
     */
    parseCardInfoWithPDSData(data) {
        

        // Verify all exists for pricing information
        window.adsk = window.adsk || {};
        window.adsk.api = window.adsk.api || {};
        window.adsk.api.pds = window.adsk.api.pds || {};
        window.adsk.api.pds.data = window.adsk.api.pds.data || {};

        let result;
        for (let x = 0; x < data.results; x++) {
            result = data.content[x];

            // Tranform the supplemental-data format to an object-key array
            result = this.transformSupplementalData(result);
            
            // Add the Pricing information if applicable
            if(this.settings.currency){
                if(result.cardData["plc"]){
                    // Check if we have a match for the PLC in the content hub info
                    console.log("this.settings.currency "+this.settings.currency)
                    if(window.adsk.api.pds.data[this.settings.currency][result.cardData["plc"]]){
                        result["pds-info"] = window.adsk.api.pds.data[this.settings.currency][result.cardData["plc"]];
                    }
                }
            }

            // save index to mapping object
            result = this.saveCardMapping(result, x);

        }
        return data
    }

            // save index to mapping object
    saveCardMapping(result, x){

            // TODO : Test this mapping on local sites with language folders
            // save index to mapping object
            if (window.location.href.indexOf("https://www.autodesk") != -1) {
                if (result.location.indexOf("/content/autodesk/europe/fr_CH/") != -1) {
                    result.location = result.location.replace("/content/autodesk/europe/fr_CH/", "/fr/");
                }
                if (result.location.indexOf("/content/autodesk/europe/de_CH/") != -1) {
                    result.location = result.location.replace("/content/autodesk/europe/de_CH/", "/de/");
                }
                if (result.location.indexOf("/content/autodesk/europe/it_CH/") != -1) {
                    result.location = result.location.replace("/content/autodesk/europe/it_CH/", "/it/");
                }
            }

            // TODO : Do we still needs this?
            // Saves the result by location for quick retrieval
            this.cardResultsMapping[result.location.toLowerCase()] = x;

            return result;
    }

    /**
     * Combine language and country to form the locale
     * @returns {string}
     */
    getLocale() {
        return this.language + '_' + this.country;
    }
}
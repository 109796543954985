export class URLManage {

// define('adsk.wp.URLBase', [
//     'adsk.data.base',
// ], function (
//     Base
// ) {


    // GOAL OF THIS URL MANAGEMENT
    // WRITE URL PARAMETERS
    // READ URL PARAMETERS
    // passing json objects for params
    // GET THE BASE LOCATION (environment knowledge of staging and production)

    

    /**
     * @constructor - Creates a new URL MANAGE
     * @extends `adsk.data.base`
     * @param -
     */
    constructor(options) {
        var defaults = {
            paramArrayDelimiter : ","
        }

        this.options = Object.assign(defaults, options);
        this.init();
    }

    // Ineritate from the BASE object
    // URLManage.prototype = Object.create(Base.prototype);

    /**
     * @method init - Start the URL Management
     */
    init() {
        this.urlRoot = this.setRootURL();
    }


    /**
     * @method getURLParams - Start the URL Management
     * @throws {object} params - params to be taken into account by the filters
     */
    getURLParams(tagPrefix) {
        var params = this.extractUrlParameters();
        // URL do not require tag extraction
        if(tagPrefix){
            for (const param in params) {
                // convert the param to array friendly value using commas or other character
                if(typeof params[param] === 'string'){
                    if(params[param].indexOf(this.options.paramArrayDelimiter) != -1){
                        var values = [];
                        var paramValues = params[param].split(this.options.paramArrayDelimiter);
                        for(var i=0;i<paramValues.length; i++) {
                            values.push(this.convertParamToTag(tagPrefix, paramValues[i]));
                        }
                        params[param] = values;
                    }else if(param == "filters"){
                        // Only 1 element in array
                        params[param] = this.convertParamToTag(tagPrefix, params[param]);
                    }
                }
            }
        }
        return params;
    }


    /**
     * @method getURLParams - Start the URL Management
     * @param {object} params - params to be set in the url 
     * @param {object} prefix - prefix to be used for tag conversion
     */
    // filter=cat--filtre--sous-filtre+cat--filtre--sous-filtre
    // filter=cat--filtre--sous-filtre1+cat--filtre--sous-filtre2
    // sortby=popular
    // sortby=a-z
    // page=1
    // page=3

    setURLParams(params, tagPrefix, deleteParams) {
        // Get current Params from url 
        var currentParams = this.extractUrlParameters();
        params = Object.assign(currentParams, params);

        if(deleteParams){
            if (params[deleteParams]) {
                delete params[deleteParams];
            }
        }

        var query = "?";
        // Convert filter into filter parameter
        for (const param in params) {
            var value = "";
            // convert the param to array friendly value using commas or other character
            // No tag prefix means we do not need to extract tags from url
            if(param == "filters" && tagPrefix){
                var values = "";
                var paramValues = params[param];
                for(var i=0;i<paramValues.length; i++) {
                    // We transform the array into a param
                    if(values != ""){
                        values += this.options.paramArrayDelimiter;
                    }
                    values += this.convertTagToParam(tagPrefix, paramValues[i]);
                }
                value = values;

            // If it is an array and use for compare we place the array like this:
            // compare=aec&compare=acdlt
            }else if(Array.isArray(params[param]) && param == "compare"){
                var values = "";
                var paramValues = params[param];
                for(var i=0;i<paramValues.length; i++) {
                    // We transform the array into a param
                    if(values != ""){
                        values += "&compare=";
                    }
                    values += paramValues[i];
                }
                value = values;
            }else{
                value = params[param];
            }
            // Empty values should not be added to URL
            if(value != ""){
                if(query!="?"){
                    query += "&";
                }
                query += param + "=" + value;
            }
        }

        // Update the browser history
        if(query != "?"){
            window.history.replaceState({}, document.title, window.location.pathname + query);
        }else{
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }


    /**
      * @method extractUrlParameters - Parse url parameters in the current request
      */
     extractUrlParameters(queryString){
        var queryParams;
        var param;
        var result = {};

        if(!queryString){
            queryString = window.location.search.substring(1);
        }
        queryParams = queryString.split('&');
        for(var i = 0; i < queryParams.length; i++) {
            param = queryParams[i].split('=', 2);

            if(param[0]){
                // Check for existing value and add to it (transform into an Array)
                var paramValue = (param.length === 1) ?
                '' : decodeURIComponent(param[1].replace(/\+/g, ' '));
                // result.param exist
                if(result[param[0]]){
                    // result.param is an array
                    if(!Array.isArray(result[param[0]])){
                        // transform into an array
                        result[param[0]] = [result[param[0]]];
                    }
                    // Add new value
                    result[param[0]].push(paramValue);
                }else{
                    result[param[0]] = paramValue;
                }
            }
        }
        return result;
    };

    /**
     * @method getBaseFolder - get the root folder of the current page (handle different domains)
     * @throws {String} url - domain + base folder autodesk.ca/fr, etc.
     */
    isAuthor() {
        if (window.location.href.match(/autodesk\.com.*\/content\/autodesk.*\.html/g)) {
            return true;
        }
        return false;
    }

    /**
     * @method convertParamToTag - translate the parameter to a full tag to be processed by the contenthub
     * @param {String} prefix - personalization-tags:all-products/
     * @param {String} param - domain + base folder autodesk.ca/fr, etc.
     * @throws {String}  - full string to be used by the contenthub
     */
    convertParamToTag(prefix, param) {
        return prefix + param.replace(/--/g, "/");
    }

    /**
     * @method convertParamToTag - translate the parameter to a full tag to be processed by the contenthub
     * @param {String} prefix - personalization-tags:all-products/
     * @param {String} tag - domain + base folder autodesk.ca/fr, etc.
     * @throws {String}  - full string to be used by the contenthub
     */
    convertTagToParam(prefix, tag) {
        return tag.replace(prefix, "").replace(/\//g, "--");
    }

    /**
     * @method getBaseFolder - get the root folder of the current page (handle different domains)
     * @throws {String} url - domain + base folder autodesk.ca/fr, aem-dc-author.awsism.autodesk.com/content/autodesk/global/en/etc
     */
    setRootURL() {
        var rootURL = "";

        // Detecting if it is preview or live domains
        if (this.isAuthor()) {
            // Preview / author domain
            rootURL = window.location.href.match(/autodesk\.com.*\/content\/autodesk.*.html/g);
            // Extract the correct folders
            rootURL = rootURL[0];
            rootURL = rootURL.replace(/.*autodesk\.com\//, "");
            rootURL = rootURL.replace("/cf#", "/");
            var previewURL = rootURL.split("/");
            previewURL = previewURL.slice(0, 4);
            rootURL = previewURL.join("/");

            rootURL = window.location.origin + "/" + rootURL;
        } else {
            var possibleLanguageFolderDomains = ["autodesk.ca/en", "autodesk.ca/fr", "autodesk.be/fr", "autodesk.be/nl", "autodesk.ch/it", "autodesk.ch/fr", "autodesk.ch/de"];
            possibleLanguageFolderDomains.forEach(domain => {
                // Check if current domain is part of the language folder list
                if (window.location.href.indexOf(domain) != -1) {
                    // Language folder found, save the language folder
                    rootURL = window.location.origin + "/" + domain.split("/")[1];
                }
            });

        }

        // Return complete url 
        return rootURL;
    }

    /**
     * @method getBaseFolder - get the root folder of the current page (handle different domains)
     * @throws {String} url - domain + base folder autodesk.ca/fr, aem-dc-author.awsism.autodesk.com/content/autodesk/global/en/etc
     */
    getRootURL() {
        // Return complete url 
        return this.urlRoot;
    }
}
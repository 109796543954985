export class GATEWAY_EVENTS {
    static get LOAD_START() {
        return "adsk.wp.gatewayEvents.load_start";
    }
    static get LOAD_DONE() {
        return "adsk.wp.gatewayEvents.load_done";
    }
    static get MENU_UPDATED() {
        return "adsk.wp.gatewayEvents.menu_updated";
    }
    static get RESULTS_UPDATED() {
        return "adsk.wp.gatewayEvents.results_updated";
    }
    static get GRID_CARD_APPENDED() {
        return "adsk.wp.gatewayEvents.grid_card_appended";
    }
    static get MENU_VISIBILITY_CHANGED() {
        return "adsk.wp.gatewayEvents.menu_visibility_changed";
    }
};
import {
  SearchAPI
} from "../search-api/search-api.js";
import {
  WPUtils
} from "../wp-utils.js";
import {
  GatewayMenu
} from './components/menu.js';
import {
  GATEWAY_EVENTS
} from './components/events.js';
import {
  GatewayGrid
} from './components/grid.js';
import {
  CompareSystem
} from './components/compare-system.js';
import {
  SelectedTagsModule
} from './components/selected-tags-module.js';
import {
  GatewayCard
} from './components/card.js';
import { callRemoveBorders, getView } from "../common/js/a-zlist.js";
import { faqAccordion } from "../common/js/faq.js";

const wpUtils = new WPUtils();
let initialOrderList;
let searchAPI;
function editModeCheck() {
  if (wpUtils.inAemEdit()) {
    return;
  }
  init();
}
function init() {
  searchAPI = new SearchAPI();

/*********  A-Z List   *********/
window.onresize = function () {
  callRemoveBorders();
}

window.addEventListener("load", function () {
  window.dispatchEvent(new Event('resize'));
});

  var resultWrapper = document.querySelector(".wp-nbResultsWrapper");
  var resultNB = document.querySelector(".wp-nbResults");


  /*=============================================
  =            Layout change on Menu Update            =
  =============================================*/
 document.body.addEventListener(GATEWAY_EVENTS.MENU_UPDATED, function(event){

  //if see more is opened display all filters
  if(document.querySelector(".workflows-filters").classList.contains("see-more-opened")) {
   document.querySelectorAll(".workflows-filters li").forEach(function(e) {
    e.style.display = "";
    });
} else {
  //display only the first 6 filters
  let list =  document.querySelectorAll(".workflows-filters li");
  for(let i = 0; i < 6; i ++) {
      list[i].style.display = "";
  }
}
   // Hide workflow filters that don't have results
  document.querySelectorAll(".workflows-filters li[results-count='0']").forEach(function(e) {
    e.style.display = "none";
  });

      // Need to re-order to brind selected filters first
      document.querySelectorAll(".workflows-filters input:checked").forEach( function(element) {
        let workflowsList =  document.querySelector(".workflows-filters ul");
        workflowsList.insertBefore(element.closest("li[results-count]"), workflowsList.childNodes[0]);
      });
    });
  /*=====  End of Layout change on Menu Update  ======*/


  /*=============================================
  =            Layout changes on LOAD DONE            =
  =============================================*/
    // LOADING STATE - OFF
    document.body.addEventListener(GATEWAY_EVENTS.LOAD_DONE, function(event) {

      document.querySelector(".wp-gateway").classList.remove("loading");

       // Update result number
       resultNB.innerHTML = event.detail.data.results;
    })
  /*=====  End of Layout changes on LOAD DONE  ======*/


  /*=============================================
  =           Create Menu Data from html            =
  =============================================*/
  var menuDataFromHTML = {
    menuItems: []
  };
  var menuDataFromHTML = {menuItems:[]};
  document.querySelectorAll(".wp-gateway-main-collapse").forEach(function (element) {
    let menuItem = {
      title: element.querySelector(".menuCollapse").innerHTML,
      menuID: element.getAttribute("data-collapse-menu-id"),
      sublinks: []
    };
    element.querySelectorAll("li").forEach(function(subItem){
      let subMenuItem = {
        title: subItem.querySelector('label').innerHTML,
        tag: subItem.querySelector('[type="checkbox"]').getAttribute("data-id")
      }

      menuItem.sublinks.push(subMenuItem);
    });
    menuDataFromHTML.menuItems.push(menuItem);
  })
  /*=====  Create Menu Data from html  ======*/
    // LOADING STATE - ON
    document.body.addEventListener(GATEWAY_EVENTS.LOAD_START, function(event){
      document.querySelector(".wp-gateway").classList.add("loading");
    });
    // Remove disabled elements from workflow
    // Re-sending the translated values since it got changed by dictionnary
    var compareSystem = new CompareSystem({
      compareViewTitleTemplate: document.querySelector(".wp-compare-view-title").innerHTML,
      compareViewSeparator: document.querySelector(".wp-compare-view-title-separator").innerHTML,
      compareViewUpsellSeparator: document.querySelector(".wp-compare-view-upsell-separator").innerHTML,
    });
  var grid = new GatewayGrid();
  var menu = new GatewayMenu({menuData: menuDataFromHTML});
  initialOrderList = document.querySelectorAll(".workflows-filters li");
  var selectedTags = new SelectedTagsModule(menu);

  /*=============================================
  =            FAQ            =
  =============================================*/
  faqAccordion();
  /*=====  End of FAQ  ======*/


  /*=============================================
  =            AFFILIATE TRAFFIC            =
  =============================================*/
  var url_param = window.location.search;
  if (url_param.indexOf('mktvar002=afc') > 0 || url_param.indexOf('PID=') > 0 || url_param.indexOf('AID=') > 0) {
    // Hide form, phone number, reseller etc.
    document.querySelector('.hide-for-afc').style.display ="none";
  }
  /*=====  End of FAQ  ======*/
}
wp_ready(function(){
  editModeCheck();
})
//Javascript function to replace $(document).ready
function wp_ready(callbackFunc) {
  if (document.readyState !== 'loading') {
      // Document is already ready, call the callback directly
      callbackFunc();
  } else if (document.addEventListener) {
      // All modern browsers to register DOMContentLoaded
      document.addEventListener('DOMContentLoaded', callbackFunc);
  } else {
      // Old IE browsers
      document.attachEvent('onreadystatechange', function() {
          if (document.readyState === 'complete') {
              callbackFunc();
          }
      });
  }
}
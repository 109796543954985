export class GatewayDropDown {

    /**
     * @constructor - Creates a new gateway code
     * @extends `adsk.data.base`
     * @param -
     */
    constructor(element) {

        //the dropdown element
        this.element = element;
        // this.parentCard = this.element.parents("wp-product-card");

        this.EVENT = {
            select_changed: "wp-gateway-select-changed"
        }

      
    }


    startListeners() {        
        //We need to create those handler to remember the function we are binding
        this.mouseClickHandler = this.mouseClick.bind(this);
        this.mouseHoverHandlerRef = this.mouseOverHandler.bind(this);
        this.mouseLeaverHandlerRef = this.mouseLeaveHandler.bind(this);
        this.keybindHandler = this.keybind.bind(this);
        this.unbindClickHandler = this.unbindClick.bind(this);

        this.element.addEventListener("click",  this.mouseClickHandler);
        this.element.addEventListener("mouseover",  this.mouseHoverHandlerRef );
        this.element.addEventListener("mouseleave", this.mouseLeaverHandlerRef);

        document.addEventListener("keydown", this.keybindHandler);
    }

    // Save data for compare tool
    keybind(event) {
        if(event.which == 27){
            this.unbindClick();
        }   
    }

    // Save data for compare tool
    resize(event) {

    }

    // Save data for compare tool
    mouseClick(event) {
        this.element.classList.add("clicked");
        this.bubbledEvent = true;
        if(!this.mouseClickBinded){
            document.body.addEventListener("click",  this.unbindClickHandler);
            // First click on the dropdown is bubbled up, we prevent it
            this.mouseClickBinded = true;
            this.element.removeEventListener("click",  this.mouseClickHandler);
        }
    }

    // Save data for compare tool
    unbindClick(event) {
        // First click on the dropdown is bubbled up, we prevent it
        if(this.bubbledEvent) {
            this.bubbledEvent = false;
            return;
        }
        
        if(event){
           
            if(this.element.contains(event.target)){
                
                this.changeValue(this.getValueFromTarget(event.target));
            }
        }

        this.element.classList.remove("clicked");
        document.body.removeEventListener("click",  this.unbindClickHandler);
        this.mouseClickBinded = false;
        this.element.addEventListener("click",  this.mouseClickHandler);
    }

    // Save data for compare tool
    getValueFromTarget(target) {
        return target.getAttribute("value");
    }

   // Save data for compare tool
    changeValue(value) {
        if(value){
            this.value = value;
            this.element.querySelector("#term-selector").value = value;
            this.element.dispatchEvent(new CustomEvent("change"));
           
            this.element.querySelector(".wp-input-selected-value-label").innerHTML = this.element.querySelector("#term-selector [value='"+value+"']").innerHTML;
            
            this.element.dispatchEvent(new CustomEvent(this.EVENT.select_changed));
        }
    }

    // Save data for compare tool
    mouseOverHandler(event) {
        this.element.classList.add("hover");
    }

    // Save data for compare tool
    mouseLeaveHandler(event) {
        this.element.classList.remove("hover");
    }
}
import {
    SearchAPI
} from "../../search-api/search-api.js";
import {
    GATEWAY_EVENTS
} from './events';
import {URLManage} from './urlManagement.js';
import compareCardTemplate from "../hbs-templates/compare-template.hbs";
import compareTreyTemplate from "../hbs-templates/compare-tray.hbs";
import {GatewayCard} from "./card.js";

import UuidUtilities from '../../common/uuid';

export class CompareSystem {

    /**
     * Compare System Manager
     */

    /**
     * @constructor - Handle the different area of compare feature
     * @extends `adsk.data.base`
     * @param -
     */
    constructor(options) {
        var defaults = {
            maxCompareProducts: 3,
            originalTreyText: '<div class="wp-compare-tray-product"><img class="wp-product-logo" src="https://damassets.autodesk.net/content/dam/autodesk/www/products/2017_badges/logo_lockups/responsive/autocad-2017-lockup-1200x132.png"><div class="wp-compare-remove-item"><i class="icon-close"></i></div></div>',

            compareViewTitleTemplate: document.querySelector(".wp-compare-view-title").innerHTML,
            compareViewSeparator: document.querySelector(".wp-compare-view-title-separator").innerHTML,
            compareViewUpsellSeparator: document.querySelector(".wp-compare-view-upsell-separator").innerHTML,

            tagPrefix: "personalization-tags:all-products/"
        }

        this.options = this.deepExtend({}, defaults, options);
        this.init();
    }


    /**
     * @method init - Start the Menu and whole app
     */
    init() {


        this.WPURLManager = new URLManage();

        this.view = CompareSystemManager.VIEW_NONE;
        this.active = false;
        this.maxCompareProducts = this.options.maxCompareProducts;
        this.originalTreyText = this.options.originalTreyText;


        this.allCompareBlock = [];
        this.numCards = document.querySelectorAll('.compare-card').length;
        this.numBlocks = document.querySelectorAll('.compare-block').length / this.numCards;

        this.chosenProducts = [];
        this.compareViewUpsellOriginal = [];
        this.checkboxPreventEvent = false;

        this.compareViewTitleTemplate = this.options.compareViewTitleTemplate;
        this.compareViewSeparator = this.options.compareViewSeparator;
        this.compareViewUpsellSeparator = this.options.compareViewUpsellSeparator;

        this.startListeners();

        var ref = this;
        document.querySelectorAll(".wp-compare-coll-upsell p").forEach(function (e) {
            ref.compareViewUpsellOriginal.push(e.innerHTML);
        });

        window.addEventListener("resize", this.resize.bind(this));

        document.body.addEventListener(GATEWAY_EVENTS.GRID_CARD_APPENDED, this.cardLoaded.bind(this));

        this.searchAPI = new SearchAPI({
            folder: this.WPURLManager.getRootURL() + "/all-products-data"
        });

        // Reset layout of compare to fix a bug with local site 
        document.querySelectorAll(".wp-compare-drawer-product").forEach( (e) => {
            e.innerHTML = this.options.originalTreyText;
        })

        this.replaceDictionaryStrings();
    }

    cardLoaded() {
        this.stopListeners();
        this.checkboxStateUpdate();
        this.startListeners();
        this.urlManagerLoad();
    }


    preloadCompareData() {
        var ref = this;
      document.body.dispatchEvent(new CustomEvent("compare_data_loading"));
        this.searchAPI.load(function (data) {
            ref.compareData = data.content;
            ref.urlManagerLoad();
        }, this.searchAPI.settings.dataID);
    }

    startListeners() {
        if (!this.checkboxListeners) {
           this.compareCheckboxChangeHandler = this.compareCheckboxChange.bind(this);
            document.querySelectorAll(".wp-compare-checkbox input[type='checkbox']").forEach( (e) => {
                e.addEventListener("change", this.compareCheckboxChangeHandler);
            })
            this.checkboxListeners = true;
            
        }
    }

    stopListeners() {
        if (this.checkboxListeners) {
            document.querySelectorAll(".wp-compare-checkbox input[type='checkbox']").forEach( (e) => {
                e.removeEventListener("change", this.compareCheckboxChangeHandler);
            })
            
            this.checkboxListeners = false;
        }
    }

    //check the box if we reach the page with the compare parameters in the url
    checkboxStateUpdate() {
        this.stopListeners();
       
        for (var a = 0; a < this.chosenProducts.length; a++) {
            document.querySelector("input[type='checkbox'][value='" + this.chosenProducts[a]["location"] + "']").checked = true;
        }

        this.startListeners();
    }

    /**
     * Activate Compare feature in the page
     * Will show all the checkbox and the trey 
     */
    setView(newView) {
        this.view = newView;
    }
    /**
     * Activate Compare feature in the page
     * Will show all the checkbox and the trey 
     */
    activateCompareFeature() {

        if (!this.active) {
            this.active = true;
            document.body.classList.add("wp-compare-card-active");
            document.body.classList.remove("wp-compare-card-deactived");

            this.launchCompareHandler = this.launchCompare.bind(this);
            this.removeProductFromCompareTrayHandler = this.removeProductFromCompareTray.bind(this);
            this.deactivateCompareFeatureHandler = this.deactivateCompareFeature.bind(this);
            this.clearTreyHandler = this.clearTrey.bind(this);

            document.querySelector(".wp-compare-drawer-container .wd-button").addEventListener("click", this.launchCompareHandler);
            document.querySelector(".wp-compare-close-tray").addEventListener("click",  this.deactivateCompareFeatureHandler);
            document.querySelector(".wp-compare-clear a").addEventListener("click" , this.clearTreyHandler);

           document.body.classList.remove("wp-compare-card-tray-deactived");
           document.body.classList.add("wp-compare-card-tray-active");


            if (this.chosenProducts.length <= 1) {
                if (!document.querySelector(".wp-compare-launch .wd-button").classList.contains("disabled")) {
                    document.querySelector(".wp-compare-launch .wd-button").classList.add("disabled");
                }
            } else {
                if (document.querySelector(".wp-compare-launch .wd-button").classList.contains("disabled")) {
                    document.querySelector(".wp-compare-launch .wd-button").classList.remove("disabled");
                }
            }
        }
        this.setView(CompareSystemManager.VIEW_TRAY);
    }

    /**
     * Activate Compare feature in the page
     * Will show all the checkbox and the trey 
     */
    deactivateCompareFeature(clearTray) {
        (typeof clearTray !== 'undefined') ? clearTray: true;

        if (this.active) {

            this.active = false;

            document.body.classList.remove("wp-compare-card-active");
            document.body.classList.add("wp-compare-card-deactived");
            document.body.classList.remove("wp-compare-card-tray-active");
            document.body.classList.add("wp-compare-card-tray-deactived");
           document.body.removeEventListener("click",  this.launchCompareHandler);
           document.body.removeEventListener("click",  this.removeProductFromCompareTrayHandler);
            this.setView(CompareSystemManager.VIEW_NONE);
            if (clearTray) {
                this.clearTrey();
            }
            document.body.removeEventListener("click", this.deactivateCompareFeatureHandler);
            document.body.removeEventListener("click", this.clearTreyHandler);
            document.body.classList.remove("wp-compare-card-max-reached");
        }
    }

    compareCheckboxChange(event) {
        if (!this.active) {
            this.activateCompareFeature();
        }


        // Start preload data for compare once you select a product
        // If preload was never done (or not executed from URL read)
        if (!this.compareData) {
            this.urlManagerLoad();
        }

        // Remove or add product
        if (event.currentTarget.checked == true) {
            if (this.chosenProducts.length < 3) {
                // Add product
                // Set the view
                this.setView(CompareSystemManager.VIEW_TRAY);

                // Add the product to the tray
                this.addProductToCompareTray(event.currentTarget.parentNode.closest(".wp-product-card").productData);


            } else {
               event.currentTarget.checked = false;
            }
        } else {
            // Remove product
            this.removeProductFromCheckbox(event.currentTarget.parentNode.closest(".wp-product-card").productData);
        }
    }

    /**
     * Add a product to the trey
     * @param
     * product: json data object 
     */
    addProductToCompareTray(product) {

        // Max product selection already reached ... do nothing
        if (this.chosenProducts.length == this.maxCompareProducts || !product) {
            return;
            // Max product is not reached but it used to be ...
        } else if (this.view == CompareSystemManager.VIEW_TRAY_FULL) {
            this.compareProductLimitUnreached();
        }


        //Add product to the tray
        var render = compareTreyTemplate(product);
        
        //get all products drawer
        let drawerProducts = document.querySelectorAll(".wp-compare-drawer-product");
        drawerProducts[this.chosenProducts.length].classList.add("wp-compare-added")
        drawerProducts[this.chosenProducts.length].innerHTML = render;

        //add event listener to the closing icon on each product in the drawer
         drawerProducts[this.chosenProducts.length].querySelector(".wp-compare-remove-item .wd-icon").addEventListener("click",this.removeProductFromCompareTrayHandler);

         this.chosenProducts.push(product);

        // Max product is reached 
        if (this.chosenProducts.length == this.maxCompareProducts) {
            this.compareProductLimitReached();
        }

        if (this.chosenProducts.length >= 1) {
            if (document.querySelector(".wp-compare-launch .wd-button").classList.contains("disabled")) {
                document.querySelector(".wp-compare-launch .wd-button").classList.remove("disabled");
            }
        }
    }

    removeProductFromCheckbox(product) {
        // Find product to be removed
        var productIndex = -1;
        for (var a = 0; a < this.chosenProducts.length; a++) {
            if (product["location"] == this.chosenProducts[a]["location"]) {
                productIndex = a;
            }
        }

        if (productIndex == -1) {
            // console.log("Remove Product from checkbox - Product not found");
            return;
        }

        let drawerProducts = document.querySelectorAll(".wp-compare-drawer-product");
        // Remove item from trey
        drawerProducts[productIndex].classList.remove("wp-compare-added");
        drawerProducts[productIndex].innerHTML = this.options.originalTreyText;

        //we want to insert it after the .wp-compare-drawwer-col:last
        document.querySelector(".wp-compare-tray-row").insertBefore(drawerProducts[productIndex].closest(".wp-compare-drawer-col"), document.querySelector(".wp-compare-drawer-col:last-child").nextSibling )

        // Remove the product from the chosenProduct array
        this.chosenProducts.splice(productIndex, 1);

        this.compareProductLimitUnreached();


        if (this.chosenProducts.length < 1) {
            if (!document.querySelector(".wp-compare-launch .wd-button").classList.contains("disabled")) {
                document.querySelector(".wp-compare-launch .wd-button").classList.add("disabled");
            }
        }
    }

  
    removeProductFromCompareTray(event) {
        // Find the current clicked product to be removed
 
        var productRemoved = event.currentTarget.closest(".wp-compare-drawer-product");
       
        //get all the products in the tray in one array
        let productsInTray = productRemoved.closest(".wp-compare-tray-row").children;
       
        let productRemovedIndex = -1;
        
        //loop in the tray to find the index of the products to be removed
        for(let i = 0; i < productsInTray.length; i++){
            if(productsInTray[i].firstChild == productRemoved) {
                productRemovedIndex = i;
            }
        }
        
        if (productRemovedIndex == -1)
            return;

        // Remove it from the tray
        productRemoved.classList.remove("wp-compare-added");
        productRemoved.innerHTML = this.options.originalTreyText;
        
         //we want to insert it after the .wp-compare-drawwer-col:last
         document.querySelector(".wp-compare-tray-row").insertBefore( productRemoved.closest(".wp-compare-drawer-col"), document.querySelector(".wp-compare-drawer-col:last-child").nextSibling );


        // Uncheck the product
        document.querySelector("input[type='checkbox'][value='" + this.chosenProducts[productRemovedIndex]["location"] + "']").checked = false;

        // Remove the product from the chosenProduct array
        this.chosenProducts.splice(productRemovedIndex, 1);
        this.compareProductLimitUnreached();

        if (this.chosenProducts.length < 1) {
            if (!document.querySelector(".wp-compare-launch .wd-button").classList.contains("disabled")) {
                document.querySelector(".wp-compare-launch .wd-button").classList.add("disabled");
            }
        }
    }

    clearTrey(event) {
        // Empty data array
        this.chosenProducts = [];
        // Remove the products from trey visually
        document.querySelectorAll(".wp-compare-drawer-product").forEach((e) => {
            e.classList.remove("wp-compare-added");
            e.innerHTML = this.options.originalTreyText;
        });
      
        // Uncheck all products
        document.querySelectorAll(".wp-compare-checkbox input[type='checkbox']").forEach(function(e) {
            e.checked = false;
        })
      
        this.compareProductLimitUnreached();

        if (!document.querySelector(".wp-compare-launch .wd-button").classList.contains("disabled")) {
            document.querySelector(".wp-compare-launch .wd-button").classList.add("disabled");
        }

        if (event) {
            event.preventDefault();
        }
    }

    compareProductLimitReached() {
        this.setView(CompareSystemManager.VIEW_TRAY_FULL);
    }

    compareProductLimitUnreached() {
        this.setView(CompareSystemManager.VIEW_TRAY);
    }

    replaceDictionaryStrings(){
        let stringsToBeReplaced = document.querySelectorAll("[data-wpd-string-key]");
        let compareContent1 = document.querySelector(".wp-compare-view");
        let compareContent2 = document.querySelector(".wp-compare-drawer-container");
    
        if (stringsToBeReplaced.length > 0) {

            //for each string key we find, find the right string we need to replace it with
            stringsToBeReplaced.forEach(function (e) {
               
                let stringkey =(e.getAttribute("data-wpd-string-key"));

                compareContent1.querySelectorAll("[data-wpd-string=" + stringkey + "]").forEach(function (i) {
                    if(i != null) {
                        i.innerHTML = e.innerHTML;
                    }
                });

                compareContent2.querySelectorAll("[data-wpd-string=" + stringkey + "]").forEach(function (i) {
                    if(i != null) {
                        i.innerHTML = e.innerHTML;
                    }
                });

            });
        }
    }


    launchCompare(event) {
        if (this.chosenProducts.length < 1) {
            return false;
        }
        // Only update URL when coming from the click action as this is called from URL on page load
        if (event) {
            this.urlManagerUpdateCompare(this.chosenProducts);
        }

       this.exitCompareHandler = this.exitCompare.bind(this);
        document.querySelector(".wp-compare-back span").addEventListener("click", this.exitCompareHandler);

        // hide search results
        document.querySelector(".wp-compare-view-list").innerHTML = "";
        var compiledTitle = "";
       
        var cardTool = new GatewayCard();//used to reuse the preRenderData
        for (var x = 0; x < this.chosenProducts.length; x++) {
            if (x > 0) {
                compiledTitle += " " + this.compareViewSeparator;
            }
            compiledTitle += this.chosenProducts[x]["cardData"]["product-name"];

            var card = this.chosenProducts[x];
            card = cardTool.preRenderData(card);
            var cardHTML = compareCardTemplate(card);
            cardHTML = this.renderCompare(cardHTML, card);
            document.querySelector(".wp-compare-view-list").insertAdjacentHTML('beforeend',cardHTML);
        }



        // Hide possible promo
        if(document.querySelector(".wp-banner-container .promotion-banner") != null) {
            document.querySelector(".wp-banner-container .promotion-banner").style.display = "none";
        }


        // Put the right title
        document.querySelector(".wp-compare-view-title").innerHTML = this.compareViewTitleTemplate + compiledTitle;

        // Remove classes that are for other views
       document.body.classList.remove("wp-compare-card-active", "wp-compare-card-tray-active");
       document.body.classList.add("wp-compare-card-launch-compare");

        // Hide search and a=z list
        document.querySelector(".wp-quick-filter, .wp-a-z-list").style.display = "none";

        this.allCompareBlock = [];
        this.numCards = document.querySelectorAll('.compare-card').length;
        this.numBlocks = document.querySelectorAll('.compare-block').length / this.numCards;

        this.resizeCompareBlock();

        // scroll to the top to show the compare view
        // 53 is the height/buffer for top menu
        window.scrollTo({
            top: document.querySelector(".wp-compare-view").offsetTop - 53,
            behavior: "smooth"
        });

        // Cancel the walkthrought 
        this.deactivateCompareFeature(false);

        this.replaceDictionaryStrings();

        // Check for upsell
        this.checkForUpsell();

        this.setView(CompareSystemManager.VIEW_COMPARE);
        this.resize();

    }

    renderCompare(cardHTML, card) {
        // Quick search and replace
        cardHTML = cardHTML.replace("[Windows]", '<i class="icon-windows wd-font-19 wd-color-gray-75 wd-vam"></i>');
        cardHTML = cardHTML.replace("[Mac]", '<i class="icon-mac wd-font-19 wd-color-gray-75 wd-vam"></i>');

        // -----------------------------------------------------------------------------
        // PRICE
        // -----------------------------------------------------------------------------


        cardHTML = this.renderPrice(cardHTML, card);

        var card = document.createElement("div");
        
        card.insertAdjacentHTML('beforeend', cardHTML);

        // Make 1 button cards 100% width buttons
        if (card.querySelectorAll(".wp-compare-button .medium-6").length == 1) {
            card.querySelector(".wp-compare-button .medium-6").classList.remove("medium-6");
        }

        return card.innerHTML;
    }

    checkForUpsell() {
        this.resetUpsell();

        var possibleAEMTags = [{
                tag: "personalization-tags:all-products/collection/me-products",
                upsellTemplate: "wp-compare-upsale-mecoll"
            },
            {
                tag: "personalization-tags:all-products/collection/aec-products",
                upsellTemplate: "wp-compare-upsale-aeccoll"
            },
            {
                tag: "personalization-tags:all-products/collection/mfg-products",
                upsellTemplate: "wp-compare-upsale-mfgcoll"
            }
        ];

        // Ignore the actual collections
        var productsToIgnore = [
            "/mecoll",
            "/pdcoll",
            "/aeccoll"
        ];

        // Variables used to determined the tag to be used for upsell
        var tagsCount = {};
        var tagMostUsed;
        var tagMostUsedCount = 0;
        var mostFrequentIndex;
        var mostFrequent;

        // If there is more than 1 collections possible - ??
        this.chosenProducts.forEach(function (product) {
            // Check if this products belong to the ignore list (collections)
            var ignoreThisCollection = false;
            productsToIgnore.forEach(function (collection) {
                if (product.location.endsWith(collection)) {
                    ignoreThisCollection = true;
                }
            })
            if (!ignoreThisCollection) {
                // gather all the collections and their score, which is the most common
                var index = 0;
                possibleAEMTags.forEach(function (collectionTag) {
                    // find if this product is tagged with an upsell 
                    if (product["aem-tags"].toString().indexOf(collectionTag.tag) != -1) {
                        // Gather score for each
                        // No score yet ...
                        if (tagsCount[collectionTag.tag] === undefined) {
                            tagsCount[collectionTag.tag] = [product];
                        } else {
                            // Score exist push to increase count/score
                            tagsCount[collectionTag.tag].push(product);
                        }
                        // Most product tag calculation as we go
                        if (tagsCount[collectionTag.tag].length > tagMostUsedCount) {
                            tagMostUsedCount = tagsCount[collectionTag.tag].length;
                            mostFrequent = collectionTag.tag;
                            mostFrequentIndex = index;
                        }
                    }
                    index++;
                });
            }
        });

        // IF MULTIPLE POSSIBILITIES PRIORITISE AEC

        this.replaceDictionaryStrings();

        // If there is a collection in the compare don't show it
        if (tagMostUsedCount > 1) {
            var productName = "";
            for (var a = 0; a < tagMostUsedCount; a++) {
                productName += tagsCount[mostFrequent][a]["cardData"]["product-name"];
                productName += this.options.compareViewUpsellSeparator;
            }
           document.querySelector("#" + possibleAEMTags[mostFrequentIndex]["upsellTemplate"] + " p").innerHTML = document.querySelector(".wp-compare-coll-upsell p").innerHTML.replace("[[products]]", productName);

            document.querySelectorAll(".wp-upsell-item").forEach(function(e) {
                e.style.display = "none";
            });

           document.querySelectorAll(".wp-compare-coll-upsell, #" + possibleAEMTags[mostFrequentIndex]["upsellTemplate"]).forEach(function(e) {
            e.style.display = '';
           });
           
        } else {
            document.querySelectorAll(".wp-compare-coll-upsell, .wp-upsell-item").forEach(function(e) {
                e.style.display ="none";
            })

        }


        // [[products]]
    }
    resetUpsell(event) {

        // Reset text to be sure it is ok
        var index = 0;
        var ref = this;
        document.querySelectorAll(".wp-compare-coll-upsell p").forEach(function (e) {
            e.innerHTML = ref.compareViewUpsellOriginal[index];
            index++;
        });

    }

    exitCompare(event) {
        this.urlManagerClearParams(true);

        this.activateCompareFeature();


        // Hide possible promo
        if(document.querySelector(".wp-banner-container .promotion-banner") != null) {
            document.querySelector(".wp-banner-container .promotion-banner").style.display = "";
        }
       
        this.checkboxStateUpdate();

        document.querySelector(".wp-compare-back span").removeEventListener("click", this.exitCompareHandler );
        document.body.classList.remove("wp-compare-card-launch-compare");
        this.setView(CompareSystemManager.VIEW_NONE);
        document.querySelector(".wp-a-z-list").style.display = "";
    
        document.querySelector(".wp-gateway").style.height = "auto";
        document.querySelector(".wp-gateway").style.overflow = "visible";

        return false;
    }

    updateURL() {
        // Reorder the product chosen to make it alpha in the url
        this.urlManagerUpdateCompare(this.chosenProducts);
    }

    resize() {
        if (this.view == CompareSystemManager.VIEW_COMPARE) {

            this.resizeCompareBlock();
            if(document.querySelector('[data-is-promoactive="true"]') != null) {
                var promoHeight = document.querySelector('[data-is-promoactive="true"]').offsetHeight;
            }
            if(promoHeight == undefined) {
                promoHeight = 0;
            }
            //Set the height
            document.querySelector(".wp-gateway").style.height = document.querySelector(".wp-compare-view").offsetHeight - promoHeight + "px",
            document.querySelector(".wp-gateway").style.overflow = "hidden";
        } 

    }

    sortNumber(a, b) {
        return a - b;
    }

    resizeCompareBlock() {

        for (let x = 1; x <= this.numBlocks; x++) {

            document.querySelectorAll('.compare-card .compare-block:nth-of-type(' + x + ') ').forEach(function (e) {
               e.style.height = '';
            });

            this.allCompareBlock.length = 0;

            var ref = this;
            document.querySelectorAll('.compare-card .compare-block:nth-of-type(' + x + ') ').forEach(function (e) {
                ref.allCompareBlock.push(parseFloat(getComputedStyle(e, null).height.replace("px", "")));
            });

            this.allCompareBlock.sort(this.sortNumber);

            var maxCompareBlock = this.allCompareBlock[this.allCompareBlock.length - 1];
            document.querySelectorAll('.compare-card .compare-block:nth-of-type(' + x + ') ').forEach(function (e) {
               e.style.height = maxCompareBlock + "px";
            });
        }
    }

    renderPrice(card, data) {
        var billingPlan = "YEAR";
        if (card.indexOf('data-pds-billing-plan="1-MONTH"') != -1) {
            billingPlan = "MONTH";
        }

        // Price replacement
        var priceStr = "";
        var beforeDiscount = "";
        var promoString = "";
        var hidePrice = false;
        var price = false;

        let compareCard = document.createElement("div");
        compareCard.insertAdjacentHTML('beforeend', card);

        try {

            price = this.getPriceInfo(data, billingPlan);

            beforeDiscount = price.beforeDiscount ? price.beforeDiscount : "";
            priceStr = price.priceStr ? price.priceStr : "";
            promoString = price.promoString ? price.promoString : "";

        } catch (e) {
            hidePrice = true;
            console.log("ERROR "+e);
        }

        if (priceStr == "") {

            compareCard.querySelector('.wp-price-plan').innerHTML = "";
            compareCard.querySelector('.wp-price-plan').setAttribute("data-wpd-string","");
            compareCard.querySelector('.wp-pricing span[data-pds-target="pricing"]').innerHTML = "";
        } else {
            compareCard.querySelector('.wp-pricing span[data-pds-target="pricing"]').innerHTML = priceStr;
        }
        // If discount price, should before discount price
        if (data["cardData"]["hide-price"] != "t") {
            if (beforeDiscount != "" && compareCard.querySelectorAll('.wp-pricing-compare').length > 0) {
                compareCard.querySelector('.wp-pricing-compare').insertAdjacentHTML('afterbegin',"<span class='wp-discount wd-light'>" + beforeDiscount + "</span> ");
            } else if (beforeDiscount != "") {
                compareCard.querySelector('.wp-pricing').insertAdjacentHTML('afterbegin', "<span class='wp-discount wd-light'>" + beforeDiscount + "</span> ");
            }
        }

        if (data["cardData"]["alternate-price-text"] && priceStr != "") {
            compareCard.querySelector('.wp-pricing').insertAdjacentHTML('afterbegin', data["cardData"]["alternate-price-text"] + ' ');
        }


        if (data["cardData"]["hide-price"] == true) {
            compareCard.querySelector('.wp-price-plan', '.pds-replacer-done').style.display = "none";
            compareCard.querySelector('.wp-pricing').classList.add("wp-alt-price");
        }

        // Don't show promotion if price is requested to be hidden
        if (promoString != "" && data["cardData"]["promotion-flag"] != 'f' && data["cardData"]["hide-price"] != "t") {
            compareCard.querySelector('.wp-pricing').insertAdjacentHTML('beforeend','<span class="wp-product-card-promo wd-font-12"><svg><use xlink:href="#icon-svg-tag-promo-solid"></use></svg><span class="wp-product-card-promo-label">' + promoString + "</span></span>");
        }
    
        return compareCard.innerHTML;
    }


    /**
     * 
     * @param {JSON} data 
     * @returns {Object} response:-beforeDiscount and priceStr
     */
    getPriceInfo(data, billingPlan, billingPeriodCount) {
        if(!billingPeriodCount){
            billingPeriodCount = "1";
        }
        // different way of parsing data depending of the pricing source
        if(data["ppg-info"] && document.documentElement.getAttribute("data-subscription-ppg-enabled") == "enabled") {
            return this.priceInfoPPG(data, billingPlan, billingPeriodCount);
        } else if(data["pds-info"]) {
            return this.priceInfoPDS(data, billingPlan, billingPeriodCount);
        }
        return {};
    }

    priceInfoPPG(data, billingPlan, billingPeriodCount) {
        var response = {};
        if(!billingPlan) {
            billingPlan = "Year";
        }
        if(data["ppg-info"]["prices"]) {
            //code goes here
            var billingPlans = data["ppg-info"]["prices"];
            response.terms = "";
            for(var i = 0; i < billingPlans.length; i++) {
                if(response.terms != "") {
                    response.terms += ",";
                    response.terms += billingPlans[i]['billingPeriodCount'] + "-" + billingPlans[i]["billingPeriod"];
                }

                if(billingPlans[i]["billingPeriodCount"] == billingPeriodCount && billingPlans[i]["billingPeriod"] == billingPlan) {
                    response.priceId = billingPlans[i]["priceId"];
                    // Check if we there is VAT, for VAT we put * after the price
                    if(billingPlans[i]["vatAmount"]) {
                        // Exception for CN we do not put * after the price
                        if(data["ppg-info"]["countryCode"] == "CN")
                            response.priceStr = billingPlans[i]["amountAfterDiscountWithVatFormatted"];
                        else
                            response.priceStr = billingPlans[i]["amountAfterDiscountWithVatFormatted"] + "*";
                    } else {
                        response.priceStr = billingPlans[i]["amountFormatted"];
                    }
                    // Check for discount
                    if(billingPlans[i]["discount"] != "0.00" && billingPlans[i]["discount"] != "0.0" && billingPlans[i]["discount"] != "0") {
                        if(billingPlans[i]["amountBeforeDiscountWithVatFormatted"]) {
                            response.beforeDiscount = billingPlans[i]["amountBeforeDiscountWithVatFormatted"]
                            // The discount for these geos is currently wrong. I will hide the "Save xxx" for now until Dev have a fix
                                        // response.discount = billingPlans[i]["price"]["discountFormatted"];
                        } else {
                            response.beforeDiscount = billingPlans[i]["amountBeforeDiscountFormatted"];
                            response.discount = billingPlans[i]["discountFormatted"];
                        }
                    }

                    if(billingPlans[i]["directToCartUrl"]) {
                        if(billingPlans[i]["directToCartUrl"].indexOf("{{visitorId}}") != -1) {
                            response.addToCartLink = billingPlans[i]["directToCartUrl"].replace('{{visitorId}}', UuidUtilities.getAndSetUuid());
                        } else if(billingPlans[i]["directToCartUrl"].indexOf("?") == -1) {
                            response.addToCartLink = billingPlans[i]["directToCartUrl"] + '?visitorId=' + UuidUtilities.getAndSetUuid();
                        } else if(billingPlans[i]["directToCartUrl"].indexOf("&") != -1) {
                            response.addToCartLink = billingPlans[i]["directToCartUrl"] + '&visitorId=' + UuidUtilities.getAndSetUuid();
                        } else if(billingPlans[i]["directToCartUrl"].indexOf("?") != -1) {
                            // inserting the visitor id in the cart link when no & is found but a ? is present
                            let position = billingPlans[i]["directToCartUrl"].indexOf("?");
                            position+=1;// include ? in the string
                            response.addToCartLink = [billingPlans[i]["directToCartUrl"].slice(0, position), 'visitorId=' + UuidUtilities.getAndSetUuid()+"&", billingPlans[i]["directToCartUrl"].slice(position)].join('');
                        }
                    }
                }

            }
        } else {
            response = false;
        }
        return response;
    }

    priceInfoPDS(data, billingPlan, billingPeriodCount) {
        var response = {};

        if(!billingPlan) {
            billingPlan = "YEAR";
        }
        if(data["pds-info"]) {
            if(data["pds-info"]["purchaseTypes"]) {
                if(data["pds-info"]["purchaseTypes"][0]["billingPlans"]) {
                    var billingPlans = data["pds-info"]["purchaseTypes"][0]["billingPlans"];
                    response.terms = "";
                    for(var i = 0; i < billingPlans.length; i++) {
                        // Gathering terms to hide term option not available
                        if(response.terms != "")
                            response.terms += ",";
                        response.terms += billingPlans[i]["billingPeriodCount"] + "-" + billingPlans[i]["billingPeriod"];

                        if(billingPlans[i]["billingPeriodCount"] == billingPeriodCount && billingPlans[i]["billingPeriod"] == billingPlan) {
                            // Find Price Id
                            response.priceId = billingPlans[i]["price"]["priceId"];
                            // Check if we there is VAT, for VAT we put * after the price
                            if(billingPlans[i]["price"]["vatAmount"]) {
                                // Exception for CN we do not put * after the price
                                if(data["pds-info"]["countryCode"] == "CN")
                                    response.priceStr = billingPlans[i]["price"]["amountAfterDiscountWithVatFormatted"];
                                else
                                    response.priceStr = billingPlans[i]["price"]["amountAfterDiscountWithVatFormatted"] + "*";
                            } else {
                                response.priceStr = billingPlans[i]["price"]["amountFormatted"];
                            }



                            // Check for discount
                            if(billingPlans[i]["price"]["discount"] != "0.00" && billingPlans[i]["price"]["discount"] != "0" && billingPlans[i]["price"]["discount"] != "0.0") {
                                if(billingPlans[i]["price"]["amountBeforeDiscountWithVatFormatted"]) {
                                    response.beforeDiscount = billingPlans[i]["price"]["amountBeforeDiscountWithVatFormatted"];
                                    // The discount for these geos is currently wrong. I will hide the "Save xxx" for now until Dev have a fix
                                    // response.discount = billingPlans[i]["price"]["discountFormatted"];
                                } else {
                                    response.beforeDiscount = billingPlans[i]["price"]["amountBeforeDiscountFormatted"];
                                    response.discount = billingPlans[i]["price"]["discountFormatted"];
                                }
                            }

                            response.promoString = "";
                            //  Check for marketing promo text
                            if(typeof billingPlans[i]["promotion"] === 'object') {
                                if("id" in billingPlans[i]["promotion"] || "promoHeadline" in billingPlans[i]["promotion"]) {
                                    response.promoString = true;
                                }
                            }



                            //   Check for add to Cart link
                            if(billingPlans[i]["directToCartUrl"]) {
                                if(billingPlans[i]["directToCartUrl"].indexOf("{{visitorId}}") != -1) {
                                    response.addToCartLink = billingPlans[i]["directToCartUrl"].replace('{{visitorId}}', UuidUtilities.getAndSetUuid());
                                } else if(billingPlans[i]["directToCartUrl"].indexOf("?") == -1) {
                                    response.addToCartLink = billingPlans[i]["directToCartUrl"] + '?visitorId=' + UuidUtilities.getAndSetUuid();
                                } else if(billingPlans[i]["directToCartUrl"].indexOf("&") != -1) {
                                    response.addToCartLink = billingPlans[i]["directToCartUrl"] + '&visitorId=' + UuidUtilities.getAndSetUuid();
                                }
                            }
                        }
                    }
                }
            }
        } else {
            response = false;
        }
        return response;
    }

    /**
     * Products card URL Manager
     * Handle the different area of compare feature
     */

    urlManagerLoad() {
        // WPURLManager.getURLParams(this.options.tagPrefix);
        var allParams = this.WPURLManager.getURLParams();

        if (allParams.compare) {

            // Extracts compare parameters from all parameters
            var params = allParams.compare;

            // We should be loading a compare!
            if (params.length > 1) {
                // Make sure there is not more than 3 products
                if (params.length > 3) {
                    params = params.slice(0, 3);
                }

                // Reorder alpha
                params.sort();
                // check if default value are already loaded
                if (this.compareData) {
                    // Product loaded check for url match
                    // console.log("Compare load found - launching compare");
                    this.urlManagerLoadProductsFromURL(params, this.compareData);
                } else {
                    // products not loaded
                    // console.log("Compare load not found - launching load");
                    this.preloadCompareData();
                }

                // Set the chosen product 
                // CompareSystemManager.chosenProducts = [];
            } else {

            }
        }
    }

    urlManagerLoadProductsFromURL(params, productData) {
        var loadedProduct = params;
        var loadedProductCount = 0;
        productData.forEach(function (productToLoad) {
            for (var a = 0; a < params.length; a++) {
                // Adding prefix to avoid possible partial match issue
                if (productToLoad.location.endsWith("/" + params[a]) || productToLoad.cardData["old-url"] == params[a]) {
                    if (productToLoad.cardData["compare-enabled"] == 1) {
                        // Save it in the array to keep the same ordering
                        loadedProduct[a] = productToLoad;
                        loadedProductCount++;
                    } else {
                        // Product compare is not enabled
                        // Removed from the product to load
                        params = params.slice(a, 1);
                    }
                }
            }
        })
        if (loadedProductCount == params.length) {
            // All product found

            // Load them in the trey
            for (var a = 0; a < loadedProductCount; a++) {
                // Add the product to the tray
                this.addProductToCompareTray(loadedProduct[a]);
            }

            this.launchCompare();
        } else {
            // console.log("Compare Error: Not all product were found. Product found: " + loadedProductCount + "/" + params.length);
        }
    }

    /**
     * Clear the compare parameters existing in the url
     * @param {*} updateBrowser 
     */
    urlManagerClearParams(updateBrowser) {

        var allParams = this.WPURLManager.getURLParams();
        this.WPURLManager.setURLParams(allParams, null, "compare");

    }

    urlManagerUpdateCompare(chosenProducts) {

        // Clear previous compare if any
        var allParams = this.WPURLManager.getURLParams();

        // Set the new compare
        var reordered = [];

        chosenProducts.forEach(function (productToLoad) {
            var productPath = productToLoad.location.split("/");
            reordered.push(productPath[productPath.length - 1]);
        });

        // Reorder alpha
        reordered.sort();

        allParams.compare = reordered;

        this.WPURLManager.setURLParams(allParams);
    }



    //Javascript version of $.extend found on http://youmightnotneedjquery.com/
     deepExtend(out)  {
        out = out || {};
      
        for (var i = 1; i < arguments.length; i++) {
          var obj = arguments[i];
      
          if (!obj)
            continue;
      
          for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (typeof obj[key] === 'object'){
                if(obj[key] instanceof Array == true)
                  out[key] = obj[key].slice(0);
                else
                  out[key] = this.deepExtend(out[key], obj[key]);
              }
              else
                out[key] = obj[key];
            }
          }
        }
      
        return out;
      };

}


const CompareSystemManager = {
    VIEW_NONE: "none",
    VIEW_TRAY: "tray",
    VIEW_TRAY_FULL: "tray",
    VIEW_COMPARE: "compare",
    urlManagerCompareParam: "compare"
};


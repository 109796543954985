export class GatewaySorting {

    // define('adsk.wp.gatewaySorting', [], function () {
    
        /**
         * @constructor - 
         * @param -
         */
        constructor(options) {
            this.SORT_BY = {};
            this.SORT_BY.POPULAR = "featured";
            this.SORT_BY.A_Z = "a_z";
        }
    
        /**
         * @method parseResults - 
         * @param {string} sortingMethod - 
         */
        sortResults(data, tags, sortingMethod, menuInstance) {
    
            if (sortingMethod == this.SORT_BY.A_Z) {
    
                // Sorting by looking at created rank property
                data.content = data.content.sort(function (a, b) {
                    if (Object.keys(a.cardData).length == 0 || Object.keys(b.cardData).length == 0) {
                        return 0;
                    } else if (a.cardData["product-name"].toLowerCase() === b.cardData["product-name"].toLowerCase()) {
                        return 0;
                    }
                    return a.cardData["product-name"].toLowerCase() > b.cardData["product-name"].toLowerCase() ? 1 : -1;
                });
    
                // Parse cards to create the rank property
                data.content.forEach(card => {
                    card = this.checkSearchPrediction(card, menuInstance);
                });
    
            } else if (sortingMethod == this.SORT_BY.POPULAR) {
    
                // Parse cards to create the rank property
                data.content.forEach(card => {
                    card = this.addRankToCard(card, tags, menuInstance);
                });
                // Sorting by looking at created rank property
                data.content = data.content.sort(function (a, b) {
                    return parseFloat(a.rank) - parseFloat(b.rank);
                });
    
            }
    
            return data;
        }
    
        /**
         * @method addRankToCard - Parse the card data to calculate the rank this card should appear
         * @param {object} card - JSON object representing the current card
         * @param {Array} tags - current selected tags
         * @throws {function} predictedSearchCallback - 
         */
        addRankToCard(card, tags, menuInstance) {
            // Check if there is any tags 
            if (Array.isArray(card["aem-tags"])) {
    
                // Number of time the card is present in the top 15
                var top15 = 0;
                // Sum of all positions (to create an average)
                var innerRank = 0;
                tags.forEach(tag => {
                    // check if any current tags have reordering values
                    var replace = tag.replace("/", "\\/");
                    replace += "\\/\\d+";
                    var findOrder = new RegExp(replace, "g");
                    //get the tag ending with the number 
                    // personalization-tags:all-products/aec-products/construction/4
                    var tagRank = card["aem-tags"].join("").match(findOrder);
                    if (tagRank !== null) {
                        // We increase the top15 counter 
                        top15++;
                        // Now need to find the value and we add it to the innerRank
                        innerRank += parseInt(tagRank[0].match(/\d/g).join(""));
                    }
                });
    
                // =========================================
                // Rank is done in 2 parts
                // =========================================
    
                // TOP 15 CONCEPT --------------------------
                // Products with multiple top15 positions should be first
                // tags.length - top15 -> this is to make the products appearing with the most top15 presence first
                // exemple 3totalTags - 3xtop15 = 0
                // exemple 3totalTags - 2xtop15 = 1
                // exemple 3totalTags - 0xtop15 = 3
    
    
                // SAME amount of top 15 -------------------
                // We use the average position of top 15 and we represent it on a scale to 100 and add it to decimal
                // We will now be able to sort the result by ordering ranks ascending
    
                // exemple 13 / 100 = 0.13
                // exemple 22 / 100 = 0.22
    
                // Adding all together gives a number 
                // exemple 0 + 0.13 = 0.13
                // exemple 1 + 0.22 = 1.22
                // exemple 3 + 0.14 = 1.22
    
                // When the product do not appear in the top15 we use the default ranking as innerRank
                if (top15 === 0) {
                    if (card.cardData["default-ordering"]) {
                        innerRank = card.cardData["default-ordering"];
                    } else {
                        innerRank = 1000000;
                    }
                }

                //Check for locale
                let htmlEl = document.querySelector('html');
                let locale = htmlEl.getAttribute('lang');
               
                //Skip if India
                if(locale != "en-IN"){
                    //Put Product Cards with promotions on top
                    if(tags.length == 0){
                        //Find Promotion
                        if(card["pds-info"]) {
                            if (card["pds-info"]["purchaseTypes"]) {
                                if (card["pds-info"]["purchaseTypes"][0]["billingPlans"]) {
                                    let billingPlans = card["pds-info"]["purchaseTypes"][0]["billingPlans"];
                                    for (let i = 0; i < billingPlans.length; i++) {
                                        //Check @if promotion object exists
                                        if (typeof billingPlans[i]["promotion"] === 'object') {
                                            innerRank -= 20000;
                                            break;
                                        }else{
                                            //Check @if there is a discount on price
                                            if(billingPlans[i]["price"]["discount"] > 0.0){
                                                innerRank -= 10000;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        } 
                        if(card["ppg-info"]){
                            if (card["ppg-info"]["prices"]) {
                                let billingPlans = card["ppg-info"]["prices"];
                                for (let i = 0; i < billingPlans.length; i++) {
                                    if(billingPlans[i]["discount"] > 0.0){
                                        innerRank -= 20000;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
    
    
                // Calculation of the rank
                // We reverse the value of the top15 so it can be sorted ascending
                card.rank = tags.length - top15 + (innerRank / 100);
    
                this.checkSearchPrediction(card, menuInstance);
    
            } else {
                // No tags or anything, last result
                card.rank = tags.length + 0.999999;
            }
            return card;
        }
    
    
        /**
         * @method checkSearchPrediction  
         * @param {object} card - JSON object representing the current card
         */
        checkSearchPrediction(card, menuInstance) {
    
            if (Array.isArray(card["aem-tags"])) {
                // As we rank results we need to calculate future possible results 
                if (menuInstance) {
                    if (menuInstance.increaseTagPredictedResults) {
                        var reg = /\d$/g;
                        card["aem-tags"].forEach(tag => {
                            // Ignore stuff thats ends with a number
                            if (tag.match(reg) === null) {
                                // Check if tag is in the menu
                                menuInstance.increaseTagPredictedResults(tag);
                            }
                        });
                    }
                }
            }
        }
    
    }
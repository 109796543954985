import {v4 as uuidv4} from 'uuid';

export default class UuidUtilities {

  static get DEFAULTS() {
    return {VISITOR_KEY: 'estoreVisitorId'}
  }

  /**
   * either get uuid from localStorage or mint, save, and get uuid from localStorage
   * @returns {string} uuid v4
   */
  static getAndSetUuid() {
    if (!localStorage.getItem(UuidUtilities.DEFAULTS.VISITOR_KEY)) {
      localStorage.setItem(UuidUtilities.DEFAULTS.VISITOR_KEY, UuidUtilities.generateUuid());
    }
    return localStorage.getItem(UuidUtilities.DEFAULTS.VISITOR_KEY);
  };

  /**
   * Generates and returns a version 4 UUID.
   * @returns {string} uuid
   *
   */
  static generateUuid() {
    return uuidv4();
  };
}
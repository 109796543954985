import {
    GATEWAY_EVENTS
} from './events';
import {
    GatewayCard
} from './card.js';
import {
    URLManage
} from './urlManagement.js';


export class GatewayGrid {

    /**
     * @constructor - Creates a new gateway code
     * @param -
     */
    constructor(options) {
        let defaults = {
            grid: ".wp-gateway-grid",
            cardsPerPage: 24,
            nbInfiniteScoll: 1, //Number of infinite scroll before using the load more
            loadMoreBt: ".wp-gateway-load-more",
            incentivePanel: "#wp-incentives",
            addToCartLabel: "#price-default-ecom-enabled-cta",
            forceMobile: false
        }

        this.options = Object.assign(defaults, options);
        this.init();

    }



    init() {
        this.URLManager = new URLManage();
        
        this.io = new IntersectionObserver(this.ioCallback.bind(this));
        this.io.POLL_INTERVAL = 100; // Time in milliseconds.

        // get load more button
        this.loadMoreBt = document.querySelector(this.options.loadMoreBt);

        // Get the page number in the url if it exists
        this.urlParams = this.URLManager.getURLParams();
        if(this.urlParams.page){
            this.currentPage = this.urlParams.page;
            // Need to know once the data is loaded that the initial load had url param to take into account
            this.initPageLoad = true;
        }else{
            this.currentPage = 1;
        }
        this.addToCartLabel = document.querySelector(this.options.addToCartLabel).innerHTML;

        this.incentivePanelHTML =  document.querySelector(this.options.incentivePanel).innerHTML;
        this.incentivePanelHTML = "<div id='wp-incentives'>"+this.incentivePanelHTML+"</div>";


        this.gridContainer = document.querySelector(this.options.grid);
        this.addListeners();
    }

    ioCallback(entries, self){
        var ref = this;
        entries.forEach(entry => {
            if(entry.isIntersecting) {
                if(entry.target.className.indexOf("wp-gateway-load-more") != -1){

                    // this is the load more button
                    self.unobserve(entry.target);

                    ref.loadMoreClick();


                }else if(entry.target.nodeName == "IMG"){
                    ref.preloadImage(entry.target);
                    // Stop watching and load the image
                    self.unobserve(entry.target);
                }
            }
        });
    }

    preloadImage(img) {
        const src = img.getAttribute('data-src');
        if(!src) { return; }
        img.src = src;
      }

    addListeners() {
        var ref = this;
        document.body.addEventListener(GATEWAY_EVENTS.RESULTS_UPDATED, function(event) {
            if(Array.isArray(event.detail.tags)){
                if(event.detail.tags.length == 0){
                    ref.dataUpdated(event.detail.data, true);
                }else{
                    ref.dataUpdated(event.detail.data, false);
                }
            }else{
                ref.dataUpdated(event.detail.data, true);
            }
        });

        document.body.addEventListener(GATEWAY_EVENTS.LOAD_START, this.changeToLoadState.bind(this));

        this.loadMoreBt.addEventListener("click",this.loadMoreClick.bind(this));
        
        window.addEventListener("resize", this.resize.bind(this));
    }


    changeToLoadState(){
        this.gridContainer.querySelector(".wp-product-card").classList.add("wp-card-loading");
        this.gridContainer.querySelector(".wp-product-card").innerHTML = '<img src="" alt="" class="wp-product-logo"><div class="price"></div><div class="wp-toggled-content"></div><div class="wp-card-bottom-align"><div class="wp-product-cta"></div><div class="wp-compare-checkbox"></div></div>';
    }

    addNewLoadingCards(){
        for(let x = 0; x < this.options.cardsPerPage; x++) {
            this.gridContainer.insertAdjacentHTML('beforeend', '<div class="wp-product-card wp-card-loading"><img src="" alt="" class="wp-product-logo"><div class="price"></div><div class="wp-toggled-content"></div><div class="wp-card-bottom-align"><div class="wp-product-cta"></div><div class="wp-compare-checkbox"></div></div></div>');
            
        }
    }

    appendIncentivesPanel(defaultLoad){
        if(defaultLoad){
            if(!this.gridContainer.classList.contains("wp-incentive-third-row"))
                this.gridContainer.classList.add("wp-incentive-third-row");
        }else{
            if(this.gridContainer.classList.contains("wp-incentive-third-row"))
                this.gridContainer.classList.remove("wp-incentive-third-row");
        }
            this.gridContainer.insertAdjacentHTML('beforeend', this.incentivePanelHTML);
    }

    dataUpdated(data, defaultLoad) {
        // Calculate nbPage
        this.nbPage = Math.ceil(data.results / this.options.cardsPerPage);
        // Check if we are at the last page
        if(this.currentPage >= this.nbPage){
            //hide load more button
            this.disableLoadMoreBt();
        }else{
            //display load more button
            this.enableLoadMoreBt();
        }

        // Don't set the page to 1 if it is the first load and we had a URL param about page
        if(!this.initPageLoad){
            this.currentPage = 1;
            this.initPageLoad = false;
        }
        // Save full data for later page change
        this.fullData = data;
        this.renderPage(defaultLoad?defaultLoad:false);
    }

    renderCards(data) {

        this.gridContainer.innerHTML = "";
        this.cards = [];
        var ref = this;
        data.content.forEach(function(cardData, index) {
            // Pre-rendering / changing the data
            var cardObj = new GatewayCard(cardData, ref.addToCartLabel);

            ref.cards.push(cardObj);

            var cardHTML = cardObj.render();

            //cardObj.element = $(cardHTML);
            ref.gridContainer.append(cardObj.element);
            cardObj.startListeners();
            ref.saveCardData(cardHTML, cardObj.getData());
        });

        this.logos = [...document.querySelectorAll(".wp-product-logo")];
        this.logos.forEach(image => {
            this.io.observe(image);
          });


        this.resize();

        document.body.dispatchEvent(new CustomEvent(GATEWAY_EVENTS.GRID_CARD_APPENDED));
    }


    renderPage(defaultLoad) {
        // Create a copy of object instead of reference
        var data = Object.create(this.fullData);
        // Modify the data to create dataset of the pages to load
        data.content = data.content.slice(0,this.currentPage * this.options.cardsPerPage);
        data.results = data.content.length;
        this.renderCards(data);
        this.appendIncentivesPanel(defaultLoad?defaultLoad:false);

        if(this.currentPage == 1){
            this.io.observe(this.loadMoreBt);
        }
    }


    changePage(newPage) {
        this.currentPage = newPage;
        // Check if we are at the last page
        if(this.currentPage >= this.nbPage){
            this.disableLoadMoreBt();
        }
        this.updateURL();
        this.renderPage();
    }


    enableLoadMoreBt(event) {
       this.loadMoreBt.style.display='';
    }

    disableLoadMoreBt(event) {
       this.loadMoreBt.style.display = 'none';
    }

    loadMoreClick(event) {
        this.addNewLoadingCards();
        this.appendIncentivesPanel();
        this.currentPage = parseInt(this.currentPage)+1;
        this.changePage(this.currentPage);
    }

    /**
     * @method load - Launch the load/search process
     */
    updateURL() {
        // Do stuff to gets parameters from menu
        // Default load
        var urlParams = {};
        urlParams.page = this.currentPage;
        this.URLManager.setURLParams(urlParams);
    }


    // Save data for compare tool
    saveCardData(card, data) {
        document.querySelector(".wp-gateway-grid > :last-child").productData = data;
    }

    // Save data for compare tool
    resize(event) {
        if(!Array.isArray(this.cards))
            return;
            let mobile = true;

            if(this.options.forceMobile){
                // Do nothing
            }else if(window.matchMedia('(min-width: 40.063em)').matches) {
                mobile = false;
            }
        this.cards.forEach(element => {
            element.resize(mobile);
        });
    }
};

/************************************************************/
/*********  Free Trial Code needed for A-Z List    *********/
/***********************************************************/
let getView = "";

//return values 0-small 1-medium 2-large
getView = function () {
  return document.querySelector(".wp-view").offsetWidth;

};

/**************************************/
/*********  A-Z List Panel    *********/
/**************************************/

let timer = "";



let removeBottomBorders = function () {

  document.querySelectorAll(".wp-a-z-list .wp-list-container .wp-link-item").forEach(function (e) {
    e.classList.remove("wp-no-border");
  });

  if (getView() > 0) {
    document.querySelectorAll(".wp-a-z-list .wp-list-container").forEach(function (e) {

      let listArray = {};

      e.querySelectorAll(".wp-link-item").forEach(function (el, index2) {


        if (listArray[el.getBoundingClientRect().top + window.scrollY] == undefined) {
          listArray[el.getBoundingClientRect().top + window.scrollY] = index2;
        } else {
          let j = 0.01;
          while (listArray[el.getBoundingClientRect().top + window.scrollY + j] != undefined) { j = j + 0.1 };
          listArray[el.getBoundingClientRect().top + window.scrollY + j] = index2;
        }

      });
      let cols = 4;
      if (getView() == 1) { cols = 3 }
      let listLinkItem = e.querySelectorAll(".wp-link-item");
      listLinkItem[listLinkItem.length - 1].classList.add("wp-no-border"); //get the last item
      let lastItem = Object.keys(listArray).length;
      for (let j in listArray) {
        if (listArray[j] == lastItem - 1) {
          delete listArray[j];
          break;
        }
      }

      for (let i = 0; i < cols; i++) {
        let largest = 0;
        for (let j in listArray) {
          if (parseFloat(j) > parseFloat(largest)) largest = parseFloat(j);
        }
        if (!e.querySelectorAll(".wp-link-item")[listArray[largest] + 1].classList.contains("wp-no-border")) {
          e.querySelectorAll(".wp-link-item")[(listArray[largest])].classList.add("wp-no-border");
        }
        delete listArray[largest];
      }

    });
  } else {

    document.querySelectorAll(".wp-a-z-list .wp-list-container").forEach(function (e) {
      let listLinkItem2 = e.querySelectorAll(".wp-link-item");
      listLinkItem2[listLinkItem2.length - 1].classList.add("wp-no-border");
    });
  }
};

function callRemoveBorders() {
  clearTimeout(timer);
  removeBottomBorders();
  timer = setTimeout(function () {
    removeBottomBorders();
  }, 800);
};

export { callRemoveBorders, getView };
import {
    GATEWAY_EVENTS
} from './events';
import {
    GatewayDropDown
} from './dropdown';
import compiledTemplate from "../hbs-templates/card-template.hbs";
import hoverintentSetup from '../hoverintent.min.js';

import UuidUtilities from '../../common/uuid';

export class GatewayCard {

    /**
     * @constructor - Creates a new gateway code
     * @extends `adsk.data.base`
     * @param -
     */
    constructor(data, defaultAddToCartLabel) {

        if(data) {
            this.data = this.preRenderData(data);
            this.addToCartLabel = defaultAddToCartLabel;
            this.termsAvailable = ["1-MONTH", "1-YEAR", "3-YEAR"];
        }
    }


    getData() {
        return this.data;
    }

    render(template) {
        var cardHTML = compiledTemplate(this.getData());
        var card = document.createElement("div");
        card.insertAdjacentHTML('beforeend', cardHTML);

        var stringsToBeReplaced = document.querySelectorAll("[data-wpd-string-key]");

        if(stringsToBeReplaced.length > 0) {

            stringsToBeReplaced.forEach(function(e) {
                // Find the strings possibly in the card template
                card.querySelectorAll("[data-wpd-string=" + e.getAttribute("data-wpd-string-key") + "]").forEach(function(string) {
                    string.innerHTML = e.innerHTML;
                });
            });
        }

        // Save default Pricing HTML for future updates
        if(!this.defaultPricingHTML) {
            this.defaultPricingHTML = card.querySelector(".wp-pricing").innerHTML;
        }

        card.querySelector(".wp-product-card").setAttribute("card-id", this.getData()["cardData"]["cardID"]);
        this.element = card.firstChild;
        var defaultTerm = this.defaultPricingTerm();

        //Switch Term
        var termSelected = defaultTerm[0] + "-" + defaultTerm[1];
        this.element.querySelector("#term-selector").value = termSelected;
        this.element.querySelector(".wp-input-selected-value-label").innerHTML = this.element.querySelector("#term-selector [value='" + termSelected + "']").innerHTML;

        this.renderPrice(defaultTerm[1], defaultTerm[0]);
        return this.element.innerHTML;
    }

    // Default Pricing copy --- pds to ppg migration
    defaultPricingTerm() {
        var data = this.getData();
        //Default priority data when no promotions (or multiple promotions)
        var priorityOrder = ["1-YEAR", "1-MONTH", "3-YEAR"];

        //Check if PPG information is available and parse it
        if(this.isPPGPricingAvailable(data)) {
            return this.pricingTermPPG(data, priorityOrder);
            //Check if PDS data is available and parse it
            
        }else if(this.isPDSPricingAvailable(data)) {
            return this.pricingTermPDS(data, priorityOrder);
        }
        return ['1', 'YEAR'];
    }

    pricingTermPPG(data, priorityOrder) {
        var foundPromo = false;
        var discountedTerms = {};
        if(data["ppg-info"]["prices"]) {
            var billingPlans = data["ppg-info"]["prices"];
            for(var i = 0; i < billingPlans.length; i++) {
                let isPromo = false;
                if((typeof billingPlans[i]["promotion"] === 'object')) {
                    if('promotionId' in billingPlans[i]['promotion'] || "promoHeadline" in billingPlans[i]["promotion"]) {
                        isPromo = true;
                    }
                }
                if(this.isDiscounted(billingPlans[i])){
                    isPromo = true;
                }
                if(isPromo){
                    var term = billingPlans[i]['billingPeriodCount'] + "-" + billingPlans[i]['billingPeriod'];
                    discountedTerms[term] = [billingPlans[i]['billingPeriodCount'], billingPlans[i]['billingPeriod']];
                    foundPromo = true;
                }
            }
            if(foundPromo) {
                for(var i = 0; i < priorityOrder.length; i++) {
                    if(discountedTerms[priorityOrder[i]]) {
                        return discountedTerms[priorityOrder[i]];
                    }

                }
            }
        }
        return ['1', 'YEAR'];
    }

    pricingTermPDS(data, priorityOrder) {
        var foundPromo = false;
        var discountedTerms = {};
        if(data["pds-info"]["purchaseTypes"]) {
            if(data["pds-info"]["purchaseTypes"][0]["billingPlans"]) {
                var billingPlans = data["pds-info"]["purchaseTypes"][0]["billingPlans"];
                for(var i = 0; i < billingPlans.length; i++) {
                    if(typeof billingPlans[i]["promotion"] === 'object') {
                        if("id" in billingPlans[i]["promotion"] || "promoHeadline" in billingPlans[i]["promotion"] || this.isDiscounted(billingPlans[i])) {
                            var term = billingPlans[i]['billingPeriodCount'] + "-" + billingPlans[i]['billingPeriod'];
                            discountedTerms[term] = [billingPlans[i]['billingPeriodCount'], billingPlans[i]['billingPeriod']];
                            foundPromo = true;
                        }
                    }
                }
                if(foundPromo) {
                    for(var i = 0; i < priorityOrder.length; i++) {
                        if(discountedTerms[priorityOrder[i]]) {
                            return discountedTerms[priorityOrder[i]];
                        }

                    }

                }
            }
        }
        return ['1', 'YEAR'];
    }

    preRenderData(card) {
        // If Data was already preRendered don't do it again
        if(card["cardData"]["preRenderData"]) {
            return card;
        }
        if(!card["cardData"]["plc"]) {
            card["cardData"]["plc"] = card["jcr:title"];
        }
        if(card["cardData"]["compare-use-for"]) {
            card["cardData"]["compare-use-for"] = this.replaceLineBreakWithList(card["cardData"]["compare-use-for"]);
        }
        if(card["cardData"]["compare-includes"]) {
            card["cardData"]["compare-includes"] = this.replaceLineBreakWithList(card["cardData"]["compare-includes"]);
        }
        if(card["cardData"]["compare-licensing"]) {
            card["cardData"]["compare-licensing"] = this.replaceLineBreakWithList(card["cardData"]["compare-licensing"]);
        }
        if(card["cardData"]["compare-what-it-does"]) {
            card["cardData"]["compare-what-it-does"] = this.replaceLineBreakWithList(card["cardData"]["compare-what-it-does"]);
        }

        //Add TID (temporary Unique ID) to product for checbkox label/input 
        card["cardData"]["tid"] = this.guid();

        if(card["cardData"]["platform-text"]) {
            // Need to extract the label "Platform  : " to put it in another div
            var extract = card["cardData"]["platform-text"].split(":");

            if(card["cardData"]["platform-text"].indexOf("：") != -1) {
                extract = card["cardData"]["platform-text"].split("：");
            }
            if(extract.length > 0) {
                if(typeof extract[0] === 'string' && extract[0] !== null) {
                    card["cardData"]["platform-text-prefix"] = extract[0].trim() + ":";
                }
                if(typeof extract[1] === 'string' && extract[1] !== null) {
                    card["cardData"]["platform-text"] = extract[1].trim();
                }
            }

            card["cardData"]["platform-text"] = card["cardData"]["platform-text"].replace("[Windows]", '<svg class="wd-icon icon-windows wd-color-gray-75 wd-vam icon-svg-windows"><use xlink:href="/content/dam/autodesk/icons.svg#icon-svg-windows"></use></svg>');
            card["cardData"]["platform-text"] = card["cardData"]["platform-text"].replace("[Mac]", '<svg class="wd-icon icon-mac wd-color-gray-75 wd-vam icon-svg-mac"><use xlink:href="/content/dam/autodesk/icons.svg#icon-svg-mac"></use></svg>');

        }

        var location = card["location"].split("/");
        if(location.length) {
            location = location[location.length - 1];
        }
        card["cardData"]["cardID"] = location;

        card["cardData"]["preRenderData"] = true;

        return card;
    }

    // Save data for compare tool
    resize(mobile) {
        this.mobile = mobile;
        var test = this.element;
        if(mobile) {
            if(!this.element.classList.contains("hover")) {
                var desc = this.element.querySelector(".wp-product-desc").innerHTML;
                if(desc != "") {
                    this.element.querySelector(".wp-mobile-product-desc").innerHTML = desc;
                    this.element.querySelector(".wp-product-desc").innerHTML = "";
                }

                this.element.classList.add("hover", "mobile");

                // Check for height element of hovered content
                this.element.querySelectorAll(".wp-toggled-content > div").forEach(function(e) {
                    e.style.height = "auto";
                    e.style.position = "static";
                });


                this.element.style.paddingBottom = parseFloat(getComputedStyle(this.element.querySelector(".wp-card-bottom-align"), null).height.replace("px", "")) + 16 + 24 + "px";

            }
        } else {

            var desc = this.element.querySelector(".wp-mobile-product-desc").innerHTML;
            if(desc != "") {
                this.element.querySelector(".wp-product-desc").innerHTML = desc;
                this.element.querySelector(".wp-mobile-product-desc").innerHTML = "";
            }

            if(this.element.classList.contains("hover")) {
                this.element.classList.remove("hover", "mobile");
            }

            // Check for height element of hovered content
            this.element.querySelectorAll(".wp-toggled-content > div").forEach(function(e) {
                e.style.height = "auto";
                e.style.position = "static";
            });



            var newHeight = 0;
            this.element.querySelectorAll(".wp-toggled-content > div").forEach(function(e) {

                //we need to change the display to block to get the height with the getComputedStyle. otherwise the height is NaN
                let currentDisplay = e.style.display;
                e.style.display = "block";

                var e_height = parseFloat(getComputedStyle(e, null).height.replace("px", ""));
                e.style.display = currentDisplay;

                if(e_height > newHeight)
                    newHeight = e_height;
            })
            this.element.querySelectorAll(".wp-toggled-content > div").forEach(function(e) {
                e.style.height = newHeight + "px";
                e.style.position = "static";
            });

            this.element.style.paddingBottom = parseFloat(getComputedStyle(this.element.querySelector(".wp-card-bottom-align"), null).height.replace("px", "")) + 16 + 24 + "px";
        }

    }



    // Save data for compare tool
    startListeners(event) {

        hoverintentSetup(this.element, this.mouseOverHandler.bind(this), this.mouseLeaveHandler.bind(this));

        this.dropdown = new GatewayDropDown(this.element.querySelector('.wp-option-dropdown'), {});

        this.dropdown.startListeners();

        this.dropdown.element.addEventListener(this.dropdown.EVENT.select_changed, this.handleTermChange.bind(this));

        this.element.querySelector(".wp-add-to-cart").addEventListener('click', this.miniCart);

    }
    // Mini Cart
    miniCart(e){
        //Check if Mini Cart Component Exists
        e.preventDefault();
        let url = this.getAttribute('href');
        if(url.includes('https://checkout.autodesk.com/') || url.includes('https://checkout-pt.autodesk.com/') || url.includes('https://checkout-apollo.autodesk.com/')){
            if(window.adsk.components.miniCart){
                //Check if url component available
                if(window.adsk.components.miniCart.addItemByUrl){
                    //console.log('addItemByUrl is available');
                    //console.log('url',url);
                    window.adsk.components.miniCart.addItemByUrl(url)
                    .catch((error) => {
                        /*if(error){
                            console.log('addItemByUrl not working');
                        }*/
                        if(window.adsk.components.miniCart.addItem){
                            let price = this.getAttribute('data-cart-priceId');
                            if(price.length != 0){
                                const item = {
                                    "priceId":this.getAttribute('data-cart-priceId'),
                                    "visitorID":UuidUtilities.getAndSetUuid(),
                                    "plc": this.getAttribute('data-cart-plc')
                                }
                                //console.log("added to cart:",item);
                                window.adsk.components.miniCart.addItem(item)
                                .catch((err) => {
                                    window.location.href = url;
                                }); 
                            }else{
                                window.location.href = url;
                            }
                        }else{
                            window.location.href = url;
                        }
                    });
                }else{
                    if(window.adsk.components.miniCart.addItem){
                        //console.log('addItemByUrl not available');
                        let price = this.getAttribute('data-cart-priceId');
                        if(price.length != 0){
                            const item = {
                                "priceId":this.getAttribute('data-cart-priceId'),
                                "visitorID":UuidUtilities.getAndSetUuid(),
                                "plc": this.getAttribute('data-cart-plc')
                            }
                            //console.log("added to cart:",item);
                            window.adsk.components.miniCart.addItem(item)
                            .catch((err) => {
                                window.location.href = url;
                            }); 
                        }else{
                            window.location.href = url;
                        }
                    }else{;
                        window.location.href = url;
                    }
                }   
            }else{
                window.location.href = url;
            }
        }else{
            window.location.href = url;
        }
    }


    // Save data for compare tool
    mouseOverHandler(event) {
        if(!this.mobile) {
            this.element.classList.add("hover");
        }
    }

    // Save data for compare tool
    mouseLeaveHandler(event) {
        if(!this.mobile) {
            this.element.classList.remove("hover");
        }
    }


    replaceLineBreakWithList(fieldString) {
        if(fieldString) {
            if(fieldString.indexOf("<ul") != -1) {
                fieldString = fieldString.replace(/\<ul\>/, '<ul class="wd-pl-20 wd-mt-8 wp-compare-use-for">').replace(/\<h4\>/, "<h4 class='wd-mt-16'>")
            } else {

                // We will not use this anymore because the translation adaptor was not reading the line break and isolating lines which they required
                fieldString = fieldString.replace(/\r\n/g, '<br />').replace(/[\r\n]/g, '<br />')
                var useForString = "";
                fieldString.split("<br />").forEach(function(line) {
                    if(line.indexOf("[t]") != -1) {
                        // if(useForString!=""){
                        useForString += '</ul>';
                        // }
                        useForString += "<h4 class='wd-mt-16'>" + line.replace("[t]", "") + "</h4>";
                        // useForString += '<ul class="wd-pl-20 wd-mt-8 wp-compare-use-for">';
                        useForString += '<ul class="wd-pl-20 wd-mt-8 wp-compare-use-for">';
                    } else if(line.indexOf("[ns]") != -1) {
                        useForString += "<li class='wp-list-no-style'>" + line.replace("[ns]", "") + "</li>";
                    } else {
                        if(line != "")
                            useForString += "<li>" + line + "</li>";
                    }
                });
                fieldString = useForString;
            }

        }
        return fieldString;
    }

    handleTermChange() {
        // Reset price layout 
        this.element.querySelector(".wp-pricing").innerHTML = this.defaultPricingHTML;

        this.renderPrice(this.dropdown.value.substring(2), parseInt(this.dropdown.value))
    }

    renderPrice(billingPlan, billingPeriodCount) {

        var card = this.element;
        if(!card.classList.contains("wp-product-card")) {
            card = this.element.querySelector(".wp-product-card");
        }
        var data = this.getData();

        // Price replacement
        var priceStr = "";
        var beforeDiscount = "";
        var promoString = "";
        var discount = "";
        var hidePrice = false;
        var price = false;

        // Should replace everything
        if(data["cardData"]["2020-gateway-price-replacement"]) {
            card.querySelector(".wp-pricing").innerHTML = data["cardData"]["2020-gateway-price-replacement"];
            card.querySelector(".wp-pricing").classList.add("wp-price-replaced");
            card.querySelector(".wp-pricing").classList.remove("wp-pricing", "wp-discounted");
        } else {
            if(data["cardData"]["2020-gateway-descriptive-pricing"]) {
                card.querySelector(".wp-pricing").innerHTML = data["cardData"]["2020-gateway-descriptive-pricing"];
                card.querySelector(".wp-pricing").classList.add("wp-price-replaced");
                card.querySelector(".wp-pricing").classList.remove("wp-pricing", "wp-discounted");
            } else {


                if(!billingPeriodCount) {
                    billingPeriodCount = 1;
                }

                if(!billingPlan) {
                    billingPlan = "YEAR";

                    if(card.innerHTML.indexOf('data-pds-billing-plan="1-MONTH"') != -1) {
                        billingPlan = "MONTH";
                    }
                }

                // Check for PDS Pricing
                try {

                    price = this.getPriceInfo(data, billingPlan, billingPeriodCount);

                    beforeDiscount = price.beforeDiscount ? price.beforeDiscount : "";
                    priceStr = price.priceStr ? price.priceStr : "";
                    promoString = price.promoString ? price.promoString : "";
                    discount = price.discount ? price.discount : "";

                } catch(e) {
                    hidePrice = true;
                    console.log("[GATEWAYS - Error Price for card] " + JSON.stringify(price));
                }


                // Update price term label (/Year)
                card.querySelector(".wp-price-plan").innerHTML = document.querySelector("#wp-price-term-" + billingPeriodCount + "-" + billingPlan.toLowerCase()).innerHTML;


                // Make sure to reset if the discount is not the term selected anymore
                if(card.querySelector('.wp-discount-wrapper') != null) {
                    card.querySelector('.wp-discount-wrapper').remove();
                }
                if(beforeDiscount != "" && discount != "") {
                    var labelDiscount = document.querySelector("#wp-discount-term").innerHTML;
                    if(labelDiscount) {
                        labelDiscount = labelDiscount.replace("---", discount);
                    }
                    // wp-discount-term">Save ---
                    card.querySelector('.wp-pricing').insertAdjacentHTML('beforeend', "<div class='wp-discount-wrapper'><span class='wp-discount wd-light'>" + beforeDiscount + "</span><span class='wp-discount-saving wd-light'>" + (labelDiscount ? labelDiscount : "") + "</span> </div>");

                    // When the "Save xxx" is not available we don't show it
                } else if(beforeDiscount != "") {
                    card.querySelector('.wp-pricing').insertAdjacentHTML('beforeend', "<div class='wp-discount-wrapper'><span class='wp-discount wd-light'>" + beforeDiscount + "</span></div>");
                }

                if(priceStr == "") {
                    card.querySelector('.wp-price-plan').innerHTML = "";
                    card.querySelector('.wp-pricing span[data-pds-target="pricing"]').innerHTML = "";
                } else {
                    card.querySelector('.wp-pricing span[data-pds-target="pricing"]').innerHTML = priceStr;
                }

                // Don't show promotion if price is requested to be hidden
                if(promoString != "" && data["cardData"]["promotion-flag"] != 'f' && data["cardData"]["hide-price"] != "t") {

                    card.querySelector(".wp-on-sale-ribbon").classList.remove("hide");
                    card.classList.add("wp-sale");

                } else {

                    // Reset the sale ribbon if it was active
                    if(card.classList.contains("wp-sale")) {
                        card.querySelector(".wp-on-sale-ribbon").classList.add("hide");
                        card.classList.remove("wp-sale");
                    }

                }


            }
        }


        // Update dropdown term to only show the ones available

        if(price.terms) {
            for(let index = 0; index < this.termsAvailable.length; index++) {
                const element = this.termsAvailable[index];
                if(price.terms.indexOf(element) == -1) {
                    card.querySelector('li[value="' + element + '"' + "]").remove();
                    card.querySelector("option[value='" + element + "']").remove();
                }
            }
        } else {
            card.querySelector(".wp-option-dropdown").style.display = 'none';
            card.querySelector(".wp-option-dropdown").classList.add("disabled");
        }

        // Take care of cases where there are multiple buy possibilities
        // We link to subscribe pages
        if(data["cardData"]["2020-gateway-cta"]) {
            card.querySelector(".wp-add-to-cart").setAttribute("href", data["cardData"]["2020-gateway-cta-url"]);
            card.querySelector(".wp-add-to-cart span:first-child").innerHTML = data["cardData"]["2020-gateway-cta"];

            // We use add to cart values
        }else if(price.addToCartLink) {

            card.querySelector(".wp-add-to-cart").setAttribute("href", price.addToCartLink);
            card.querySelector(".wp-add-to-cart span:first-child").innerHTML = this.addToCartLabel;
            card.querySelector(".wp-add-to-cart").setAttribute("data-cart-priceId", price.priceId);
            //console.log(price.priceID);

        } else {
            // We have a price but no direct to cart link, we direct to subscribe and hide term selector
            card.querySelector(".wp-add-to-cart").setAttribute("href", data["cardData"]["subscribe-link"]);
            card.querySelector(".wp-add-to-cart span:first-child").innerHTML = data["cardData"]["subscribe-cta-link"];
            card.querySelector(".wp-option-dropdown").style.display = 'none';
            card.querySelector(".wp-option-dropdown").classList.add("disabled");
        }

        if(data["cardData"]["price-multiple-products-option-cta"]) {
            card.querySelector(".wp-add-to-cart span:first-child").innerHTML = data["cardData"]["price-multiple-products-option-cta"];
        }

        return card.innerHTML;
    }

    /**
     * 
     * @param {JSON} data 
     * @returns {Object} response:-beforeDiscount and priceStr
     */


    getPriceInfo(data, billingPlan, billingPeriodCount) {
        // different way of parsing data depending of the pricing source
        if(this.isPPGPricingAvailable(data)) {
            return this.priceInfoPPG(data, billingPlan, billingPeriodCount);
        } else if(this.isPDSPricingAvailable(data)) {
            return this.priceInfoPDS(data, billingPlan, billingPeriodCount);
        }
        //default = no pricing ... empty object
        return {};
    }

    priceInfoPPG(data, billingPlan, billingPeriodCount) {
        var response = {};
        if(!billingPlan) {
            billingPlan = "Year";
        }
        if(data["ppg-info"]["prices"]) {
            //code goes here
            var billingPlans = data["ppg-info"]["prices"];
            response.terms = "";
            for(var i = 0; i < billingPlans.length; i++) {
                if(response.terms != "") {
                    response.terms += ",";
                }
                response.terms += billingPlans[i]['billingPeriodCount'] + "-" + billingPlans[i]["billingPeriod"];

                if(billingPlans[i]["billingPeriodCount"] == billingPeriodCount && billingPlans[i]["billingPeriod"] == billingPlan) {
                    response.priceId = billingPlans[i]["priceId"];
                    // Check if we there is VAT, for VAT we put * after the price
                    if(billingPlans[i]["vatAmount"]) {
                        // Exception for CN we do not put * after the price
                        if(data["ppg-info"]["countryCode"] == "CN")
                            response.priceStr = billingPlans[i]["amountAfterDiscountWithVatFormatted"];
                        else
                            response.priceStr = billingPlans[i]["amountAfterDiscountWithVatFormatted"] + "*";
                    } else {
                        response.priceStr = billingPlans[i]["amountFormatted"];
                    }

                    // Check for discount
                    response.promoString = "";
                    if(billingPlans[i]["discount"] != "0.00" && billingPlans[i]["discount"] != "0.0" && billingPlans[i]["discount"] != "0") {
                        if(billingPlans[i]["amountBeforeDiscountWithVatFormatted"]) {
                            response.beforeDiscount = billingPlans[i]["amountBeforeDiscountWithVatFormatted"]
                            // The discount for these geos is currently wrong. I will hide the "Save xxx" for now until Dev have a fix
                            // response.discount = billingPlans[i]["price"]["discountFormatted"];
                        } else {
                            response.beforeDiscount = billingPlans[i]["amountBeforeDiscountFormatted"];
                            // response.discount = billingPlans[i]["discountFormatted"];
                        }
                    }

                    //Check if we should flag the price as discounted and display promotion bar
                    // We check if the price is different
                    response.promoString = this.isDiscounted(billingPlans[i]);//parseFloat(billingPlans[i].amount) < parseFloat(billingPlans[i].amountBeforeDiscount);

                    if(billingPlans[i]["directToCartUrl"]) {
                        if(billingPlans[i]["directToCartUrl"].indexOf("{{visitorId}}") != -1) {
                            response.addToCartLink = billingPlans[i]["directToCartUrl"].replace('{{visitorId}}', UuidUtilities.getAndSetUuid());
                        } else if(billingPlans[i]["directToCartUrl"].indexOf("?") == -1) {
                            response.addToCartLink = billingPlans[i]["directToCartUrl"] + '?visitorId=' + UuidUtilities.getAndSetUuid();
                        } else if(billingPlans[i]["directToCartUrl"].indexOf("&") != -1) {
                            response.addToCartLink = billingPlans[i]["directToCartUrl"] + '&visitorId=' + UuidUtilities.getAndSetUuid();
                        } else if(billingPlans[i]["directToCartUrl"].indexOf("?") != -1) {
                            // inserting the visitor id in the cart link when no & is found but a ? is present
                            let position = billingPlans[i]["directToCartUrl"].indexOf("?");
                            position+=1;// include ? in the string
                            response.addToCartLink = [billingPlans[i]["directToCartUrl"].slice(0, position), 'visitorId=' + UuidUtilities.getAndSetUuid()+"&", billingPlans[i]["directToCartUrl"].slice(position)].join('');
                        }
                    }
                }
            }
        } else {
            response = false;
        }
        return response;
    }

    priceInfoPDS(data, billingPlan, billingPeriodCount) {
        var response = {};

        if(!billingPlan) {
            billingPlan = "YEAR";
        }
        if(data["pds-info"]) {
            if(data["pds-info"]["purchaseTypes"]) {
                if(data["pds-info"]["purchaseTypes"][0]["billingPlans"]) {
                    var billingPlans = data["pds-info"]["purchaseTypes"][0]["billingPlans"];
                    response.terms = "";
                    for(var i = 0; i < billingPlans.length; i++) {
                        // Gathering terms to hide term option not available
                        if(response.terms != "")
                            response.terms += ",";
                        response.terms += billingPlans[i]["billingPeriodCount"] + "-" + billingPlans[i]["billingPeriod"];

                        if(billingPlans[i]["billingPeriodCount"] == billingPeriodCount && billingPlans[i]["billingPeriod"] == billingPlan) {
                            // Find Price Id
                            response.priceId = billingPlans[i]["price"]["priceId"];
                            // Check if we there is VAT, for VAT we put * after the price
                            if(billingPlans[i]["price"]["vatAmount"]) {
                                // Exception for CN we do not put * after the price
                                if(data["pds-info"]["countryCode"] == "CN")
                                    response.priceStr = billingPlans[i]["price"]["amountAfterDiscountWithVatFormatted"];
                                else
                                    response.priceStr = billingPlans[i]["price"]["amountAfterDiscountWithVatFormatted"] + "*";
                            } else {
                                response.priceStr = billingPlans[i]["price"]["amountFormatted"];
                            }



                            // Check for discount
                            if(billingPlans[i]["price"]["discount"] != "0.00" && billingPlans[i]["price"]["discount"] != "0" && billingPlans[i]["price"]["discount"] != "0.0") {
                                if(billingPlans[i]["price"]["amountBeforeDiscountWithVatFormatted"]) {
                                    response.beforeDiscount = billingPlans[i]["price"]["amountBeforeDiscountWithVatFormatted"];
                                    // The discount for these geos is currently wrong. I will hide the "Save xxx" for now until Dev have a fix
                                    // response.discount = billingPlans[i]["price"]["discountFormatted"];
                                } else {
                                    response.beforeDiscount = billingPlans[i]["price"]["amountBeforeDiscountFormatted"];
                                    response.discount = billingPlans[i]["price"]["discountFormatted"];
                                }
                            }

                            response.promoString = "";
                            //  Check for marketing promo text
                            if(typeof billingPlans[i]["promotion"] === 'object') {
                                if("id" in billingPlans[i]["promotion"] || "promoHeadline" in billingPlans[i]["promotion"]) {
                                    response.promoString = true;
                                }
                            }



                            //   Check for add to Cart link
                            if(billingPlans[i]["directToCartUrl"]) {
                                if(billingPlans[i]["directToCartUrl"].indexOf("{{visitorId}}") != -1) {
                                    response.addToCartLink = billingPlans[i]["directToCartUrl"].replace('{{visitorId}}', UuidUtilities.getAndSetUuid());
                                } else if(billingPlans[i]["directToCartUrl"].indexOf("?") == -1) {
                                    response.addToCartLink = billingPlans[i]["directToCartUrl"] + '?visitorId=' + UuidUtilities.getAndSetUuid();
                                } else if(billingPlans[i]["directToCartUrl"].indexOf("&") != -1) {
                                    response.addToCartLink = billingPlans[i]["directToCartUrl"] + '&visitorId=' + UuidUtilities.getAndSetUuid();
                                }
                            }
                        }
                    }
                }
            }
        } else {
            response = false;
        }
        return response;
    }

    isDiscounted(price) {
        let isVat = price.vatAmount > 0;
        let isDiscounted = false;
        try {
            isDiscounted = isVat
                ? parseFloat(price.amountAfterDiscountWithVat) < parseFloat(price.amountBeforeDiscountWithVat)
                : parseFloat(price.amount) < parseFloat(price.amountBeforeDiscount);
        } catch (err) {
            console.error('Utilities: isDiscounted ', err);
        }
        return isDiscounted;
    }

    isPPGPricingAvailable(data){
        // When we will be ready to sync with the feature flag of dotcom
        if(data["ppg-info"] && document.documentElement.getAttribute("data-subscription-ppg-enabled") == "enabled") {
            return true;
        }
    }

    isPDSPricingAvailable(data){
        if(data["pds-info"]) {
            return true;
        }
    }

    guid() {
        function s4() {
            const arr = new Uint8Array(1)
            return Math.floor((1 + crypto.getRandomValues(arr)) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    // check if there is a discount in the product properties
    productHasDiscount(product) {
        try {
            var billingPlans = product["pds-info"]["purchaseTypes"][0]["billingPlans"];
            for(var i = 0; i < billingPlans.length; i++) {
                if(billingPlans[i]["billingPeriodCount"] == "1" && billingPlans[i]["billingPeriod"] == "YEAR") {
                    if(billingPlans[i]["price"]["discount"] != "0.00" && billingPlans[i]["price"]["discount"] != "0") {
                        return true;
                    }
                }
            }
        } catch(e) {}
        return false;
    }

}